import { createContext, useState, useEffect, useContext } from "react";
import randomNumber from "../utils/randomNumber";
import questionsArray from "../content/adj-endings/adj-endings";
import { FirebaseContext } from "./firebase.context";

let randomQuestionNumber = randomNumber(0, questionsArray.length - 1);

export const QuestionsContext = createContext({
  currentQuestionNumber: null,
  setCurrentQuestionNumber: () => null,
  question: null,
  setQuestion: () => null,
  activeQuestionsArray: {},
  setActiveQuestionsArray: () => null,
  questionsLeft: null,
  setQuestionsLeft: () => null,
  prepareQuestionsArray: () => null,
  questionsCount: null,
  setQuestionsCount: () => null,
  mistakesCount: null,
  setMistakesCount: () => null,
  hintsCount: null,
  setHintsCount: () => null,
  initialTime: null,
  setInitialTime: () => null,
});

export const QuestionsProvider = ({ children }) => {
  const { mistakes, queryCardsFromFirebase } = useContext(FirebaseContext);
  const [activeQuestionsArray, setActiveQuestionsArray] =
    useState(questionsArray);
  const [question, setQuestion] = useState(
    // questionsArray[randomQuestionNumber][0]
    questionsArray[0]
  );
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [questionsLeft, setQuestionsLeft] = useState(
    activeQuestionsArray.length
  );
  const [questionsCount, setQuestionsCount] = useState(0);
  const [mistakesCount, setMistakesCount] = useState(0);
  const [hintsCount, setHintsCount] = useState(0);
  const [initialTime, setInitialTime] = useState(0);

  // useEffect(() => {
  //   queryCardsFromFirebase();
  // }, []);

  const prepareQuestionsArray = (cardscount, isReview) => {
    // is it review? if yes, filter questions
    let workingArray = [];
    if (!isReview) {
      workingArray = questionsArray;
    } else {
      workingArray = questionsArray.filter((question) =>
        mistakes.includes(question.id)
      );
    }

    // create tempArray with "false" values
    // false/true in tempArray will signal if question was already used/added
    const tempArray = [];
    const totalCardsNumber = workingArray.length;
    for (let i = 0; i < totalCardsNumber; i++) {
      tempArray.push(false);
    }

    // fill cardsSelectionArr with random questions from workingArray
    const cardsSelectionArr = [];
    for (let i = 0; i < cardscount; i++) {
      const x = randomNumber(0, workingArray.length - 1);
      if (tempArray[x]) {
        i--;
      } else {
        // create a deep copy

        // const objClone = structuredClone(workingArray[x]);
        const objClone = JSON.parse(JSON.stringify(workingArray[x]));

        // cardsSelectionArr.push(questionsArray[x]);
        cardsSelectionArr.push(objClone);
        tempArray[x] = true;
      }
    }
    setActiveQuestionsArray(cardsSelectionArr);
  };

  useEffect(() => {
    let x = 0;
    activeQuestionsArray.forEach((element) => {
      if (!element.answered) {
        x++;
      }
    });
    setQuestionsLeft(x);
  }, [activeQuestionsArray]);

  const value = {
    question,
    setQuestion,
    activeQuestionsArray,
    setActiveQuestionsArray,
    currentQuestionNumber,
    setCurrentQuestionNumber,
    questionsLeft,
    setQuestionsLeft,
    prepareQuestionsArray,
    questionsCount,
    setQuestionsCount,
    mistakesCount,
    setMistakesCount,
    hintsCount,
    setHintsCount,
    initialTime,
    setInitialTime,
  };
  return (
    <QuestionsContext.Provider value={value}>
      {children}
    </QuestionsContext.Provider>
  );
};
