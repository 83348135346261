const questionsArray = [
  {
    text: "Er ist ein netter Mann, aber er hat einen goldenen Zahn. Der &golden Zahn scheint wie eine Sonne.",
    golden: ["goldenen", "goldene", "goldnem"],
    golden_key: "goldene",
    img: "tooth-golden.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Zahn (maskulin)",
      "Nominativ: der goldene Zahn",
      "Akkusativ: den goldenen Zahn",
      "Dativ: dem goldenen Zahn",
    ],
    hint_correct: 1,
    id: "001",
  },
  {
    text: "Er hat einen goldenen und einen eisernen Zahn. Ich glaube, ich mag den &golden Zahn mehr.",
    golden: ["goldenen", "goldene", "goldener", "goldnem"],
    golden_key: "goldenen",
    img: "tooth-golden-metal.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Zahn (maskulin)",
      "Nominativ: der goldene Zahn",
      "Akkusativ: den goldenen Zahn",
      "Dativ: dem goldenen Zahn",
    ],
    hint_correct: 2,
    id: "002",
  },
  {
    text: "Er ist ein netter Mann, aber er hat einen fehlenden Zahn. Mit dem &fehlend Zahn sieht er aus wie ein Pirat.",
    fehlend: ["fehlender", "fehlenden", "fehlendem", "fehlende"],
    fehlend_key: "fehlenden",
    img: "tooth-missing.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Zahn (maskulin)",
      "Nominativ: der fehlende Zahn",
      "Akkusativ: den fehlenden Zahn",
      "Dativ: dem fehlenden Zahn",
    ],
    hint_correct: 3,
    id: "003",
  },
  // {
  //   text: "Sein &golden Zahn scheint wie eine Sonne.",
  //   golden: ["goldener", "goldenen", "goldene", "goldenem"],
  //   golden_key: "goldener",
  //   img: "tooth-golden.svg",
  //   answered: 0,
  //   visited: false,
  //   hint: [
  //     "der Zahn (maskulin)",
  //     "Nominativ: sein goldener Zahn",
  //     "Akkusativ: seinen goldenen Zahn",
  //     "Dativ: seinem goldenen Zahn",
  //   ],
  //   hint_correct: 1,
  //   id: "004",
  // },
  {
    text: "Er ist ein netter Mann, aber er hat einen &fehlend Zahn. ",
    fehlend: ["fehlender", "fehlenden", "fehlendem", "fehlende"],
    fehlend_key: "fehlenden",
    img: "tooth-missing.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Zahn (maskulin)",
      "Nominativ: ein fehlender Zahn",
      "Akkusativ: einen fehlenden Zahn",
      "Dativ: einem fehlenden Zahn",
    ],
    hint_correct: 2,
    id: "005",
  },
  {
    text: "Mit einem &fehlend Zahn sieht er aus wie ein Pirat.",
    fehlend: ["fehlender", "fehlenden", "fehlendem", "fehlende"],
    fehlend_key: "fehlenden",
    img: "tooth-missing.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Zahn (maskulin)",
      "Nominativ: ein fehlender Zahn",
      "Akkusativ: einen fehlenden Zahn",
      "Dativ: einem fehlenden Zahn",
    ],
    hint_correct: 3,
    id: "006",
  },
  {
    text: "Er ist ein netter Mann, und er hat goldene Zähne. Die &golden Zähne scheinen wie eine Sonne",
    golden: ["goldenen", "goldene", "goldnem", "goldener"],
    golden_key: "goldenen",
    img: "teeth-golden.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Zähne (plural)",
      "Nominativ: die goldenen Zähne",
      "Akkusativ: die goldenen Zähne",
      "Dativ: den goldenen Zähnen",
    ],
    hint_correct: 1,
    id: "007",
  },
  {
    text: "Er ist ein netter Mann, aber er hat fehlende Zähne. Mit den &fehlend Zähne sieht er aus wie ein Pirat.",
    fehlend: ["fehlender", "fehlenden", "fehlende", "fehlendem"],
    fehlend_key: "fehlenden",
    img: "teeth-missing.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Zähne (plural)",
      "Nominativ: die fehlenden Zähne",
      "Akkusativ: die fehlenden Zähne",
      "Dativ: den fehlenden Zähnen",
    ],
    hint_correct: 3,
    id: "008",
  },
  // {
  //   text: "Seine &golden Zähne scheinen wie eine Sonne.",
  //   golden: ["goldene", "goldenen", "goldnem", "goldener"],
  //   golden_key: "goldenen",
  //   img: "teeth-golden.svg",
  //   answered: 0,
  //   visited: false,
  //   hint: [
  //     "die Zähne (plural)",
  //     "Nominativ: seine goldenen Zähne",
  //     "Akkusativ: seine goldenen Zähne",
  //     "Dativ: seinen goldenen Zähnen",
  //   ],
  //   hint_correct: 1,
  //   id: "009",
  // },
  // {
  //   text: "Mit seinen &fehlend Zähnen sieht er aus wie ein Pirat",
  //   fehlend: ["fehlender", "fehlenden", "fehlende", "fehlendem"],
  //   fehlend_key: "fehlenden",
  //   img: "teeth-missing.svg",
  //   answered: 0,
  //   visited: false,
  //   hint: [
  //     "die Zähne (plural)",
  //     "Nominativ: seine fehlenden Zähne",
  //     "Akkusativ: seine fehlenden Zähne",
  //     "Dativ: seinen fehlenden Zähnen",
  //   ],
  //   hint_correct: 3,
  //   id: "010",
  // },
  {
    text: "Er ist ein netter Mann, aber er hat &fehlend Zähne.",
    fehlend: ["fehlender", "fehlenden", "fehlende", "fehlendem"],
    fehlend_key: "fehlende",
    img: "teeth-missing.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Zähne (plural)",
      "Nominativ: fehlende Zähne",
      "Akkusativ: fehlende Zähne",
      "Dativ: fehlenden Zähnen",
    ],
    hint_correct: 2,
    id: "011",
  },
  {
    text: "Die &vegetarisch Wurst, die du gekauft hast, ist keine echte Wurst. Aber sie ist lecker!",
    vegetarisch: [
      "vegetarischen",
      "vegetarischer",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarische",
    img: "sausage-green.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Wurst (feminine)",
      "Nominativ: die vegetarische Wurst",
      "Akkusativ: die vegetarische Wurst",
      "Dativ: der vegetarischen Wurst",
    ],
    hint_correct: 1,
    id: "012",
  },
  {
    text: "Wir haben eine vegetarische Wurst und eine echte Wurst. Natürlich möchte ich die &echt Wurst haben.",
    echt: ["echter", "echten", "echte", "echtem"],
    echt_key: "echte",
    img: "sausages.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Wurst (feminine)",
      "Nominativ: die echte Wurst",
      "Akkusativ: die echte Wurst",
      "Dativ: der echten Wurst",
    ],
    hint_correct: 2,
    id: "013",
  },
  {
    text: "Diese Wurst war nicht lecker. Vielleicht habe ich bei der &nächst Wurst mehr Glück.",
    nächst: ["nächster", "nächsten", "nächste", "nächstem"],
    nächst_key: "nächsten",
    img: "sausage-burnt.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Wurst (feminine)",
      "Nominativ: die nächste Wurst",
      "Akkusativ: die nächste Wurst",
      "Dativ: der nächsten Wurst",
    ],
    hint_correct: 3,
    id: "014",
  },
  {
    text: "Jeden Tag isst er eine &fett Wurst zum Mittagessen. Es ist ein Wunder, dass er noch nicht tot ist",
    fett: ["fetter", "fetten", "fette", "fettem"],
    fett_key: "fette",
    img: "sausage.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Wurst (feminine)",
      "Nominativ: eine fette Wurst",
      "Akkusativ: eine fette Wurst",
      "Dativ: der fetten Wurst",
    ],
    hint_correct: 2,
    id: "015",
  },
  {
    text: "Die &vegetarisch Würste, die du gekauft hast, sind keine echte Würste. Aber sie sind lecker!",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarischen",
    img: "sausages-green.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Würste (plural)",
      "Nominativ: die vegetarischen Würste",
      "Akkusativ: die vegetarischen Würste",
      "Dativ: den vegetarischen Würste",
    ],
    hint_correct: 1,
    id: "016",
  },
  {
    text: "Wir haben vegetarische Würste und echte Würste. Natürlich möchte ich die &vegetarisch Würste haben.",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarischen",
    img: "sausages.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Würste (plural)",
      "Nominativ: die vegetarischen Würste",
      "Akkusativ: die vegetarischen Würste",
      "Dativ: den vegetarischen Würste",
    ],
    hint_correct: 2,
    id: "017",
  },
  {
    text: "Diese Würste waren nicht lecker. Vielleicht habe ich bei den &nächst Würsten mehr Glück.",
    nächst: ["nächster", "nächsten", "nächste", "nächstem"],
    nächst_key: "nächsten",
    img: "sausages-burnt.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Würste (plural)",
      "Nominativ: die nächsten Würste",
      "Akkusativ: die nächsten Würste",
      "Dativ: den nächsten Würste",
    ],
    hint_correct: 3,
    id: "018",
  },
  {
    text: "&Vegetarisch Würste sind keine echte Würste. Aber sie sind lecker!",
    Vegetarisch: [
      "Vegetarischer",
      "Vegetarischen",
      "Vegetarische",
      "Vegetarischem",
    ],
    Vegetarisch_key: "Vegetarische",
    img: "sausages-green.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Würste (plural)",
      "Nominativ: vegetarische Würste",
      "Akkusativ: vegetarische Würste",
      "Dativ: vegetarischen Würsten",
    ],
    hint_correct: 1,
    id: "019",
  },
  {
    text: "Jeden Tag isst er &fett Würste zum Mittagessen. Es ist ein Wunder, dass er noch nicht tot ist",
    fett: ["fetter", "fetten", "fette", "fettem"],
    fett_key: "fette",
    img: "sausages-real.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Würste (plural)",
      "Nominativ: fette Würste",
      "Akkusativ: fette Würste",
      "Dativ: fetten Würsten",
    ],
    hint_correct: 2,
    id: "20",
  },
  {
    text: "Durch den &tragisch Unfall, bei dem so viele Menschen verletzt wurden, hat er sich nur einen Finger gebrochen.",
    tragisch: ["tragischer", "tragischen", "tragische", "tragischem"],
    tragisch_key: "tragischen",
    img: "broken-finger.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Unfall (maskulin)",
      "Nominativ: der tragische Unfall",
      "Akkusativ: den tragischen Unfall",
      "Dativ: dem tragischen Unfall",
    ],
    hint_correct: 2,
    id: "21",
  },
  {
    text: "Bei dem &tragisch Unfall, durch den so viele Menschen verletzt wurden, hat er sich nur einen Finger gebrochen.",
    tragisch: ["tragischer", "tragischen", "tragische", "tragischem"],
    tragisch_key: "tragischen",
    img: "broken-finger.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Unfall (maskulin)",
      "Nominativ: der tragische Unfall",
      "Akkusativ: den tragischen Unfall",
      "Dativ: dem tragischen Unfall",
    ],
    hint_correct: 3,
    id: "22",
  },
  {
    text: "Es war ein &tragisch Unfall: Er hatte sich den Finger gebrochen.",
    tragisch: ["tragischer", "tragischen", "tragische", "tragischem"],
    tragisch_key: "tragischer",
    img: "broken-finger.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Unfall (maskulin)",
      "Nominativ: ein tragischer Unfall",
      "Akkusativ: einen tragischen Unfall",
      "Dativ: einem tragischen Unfall",
    ],
    hint_correct: 1,
    id: "23",
  },
  {
    text: "Durch einen &tragisch Unfall hatte er sich einen Finger gebrochen.",
    tragisch: ["tragischer", "tragischen", "tragische", "tragischem"],
    tragisch_key: "tragischen",
    img: "broken-finger.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Unfall (maskulin)",
      "Nominativ: ein tragischer Unfall",
      "Akkusativ: einen tragischen Unfall",
      "Dativ: einem tragischen Unfall",
    ],
    hint_correct: 2,
    id: "24",
  },
  {
    text: "Bei einem &tragisch Unfall hatte er sich einen Finger gebrochen.",
    tragisch: ["tragischer", "tragischen", "tragische", "tragischem"],
    tragisch_key: "tragischen",
    img: "broken-finger.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Unfall (maskulin)",
      "Nominativ: ein tragischer Unfall",
      "Akkusativ: einen tragischen Unfall",
      "Dativ: einem tragischen Unfall",
    ],
    hint_correct: 3,
    id: "25",
  },
  {
    text: "Eine Krankenschwester zu werden ist ein &groß Traum von mir.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "großer",
    img: "nurse.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Traum (maskulin)",
      "Nominativ: ein großer Traum",
      "Akkusativ: einen großen Traum",
      "Dativ: einem großen Traum",
    ],
    hint_correct: 1,
    id: "26",
  },
  {
    text: "Ich habe einen &groß Traum. Ich möchte eine Krankenschwester werden.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "großen",
    img: "nurse.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Traum (maskulin)",
      "Nominativ: ein großer Traum",
      "Akkusativ: einen großen Traum",
      "Dativ: einem großen Traum",
    ],
    hint_correct: 2,
    id: "27",
  },
  {
    text: "Ich möchte Ihnen von einem &groß Traum erzählen. Ich möchte Krankenschwester werden!",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "großen",
    img: "nurse.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Traum (maskulin)",
      "Nominativ: ein großer Traum",
      "Akkusativ: einen großen Traum",
      "Dativ: einem großen Traum",
    ],
    hint_correct: 3,
    id: "28",
  },
  {
    text: "Für mich war es der &perfekt Urlaub, von dem ich geträumt habe. Ich lag den ganzen Tag auf dem Sofa.",
    perfekt: ["perfekter", "perfekten", "perfekte", "perfektem"],
    perfekt_key: "perfekte",
    img: "man-on-sofa.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Urlaub (maskulin)",
      "Nominativ: der perfekte Urlaub",
      "Akkusativ: den perfekten Urlaub",
      "Dativ: dem perfekten Urlaub",
    ],
    hint_correct: 1,
    id: "29",
  },
  {
    text: "Ich hatte den &perfekt Urlaub, von dem ich geträumt habe. Ich lag den ganzen Tag auf dem Sofa.",
    perfekt: ["perfekter", "perfekten", "perfekte", "perfektem"],
    perfekt_key: "perfekten",
    img: "man-on-sofa.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Urlaub (maskulin)",
      "Nominativ: der perfekte Urlaub",
      "Akkusativ: den perfekten Urlaub",
      "Dativ: dem perfekten Urlaub",
    ],
    hint_correct: 2,
    id: "30",
  },
  {
    text: "Ich war in dem &perfekt Urlaub, von dem ich geträumt habe. Ich lag den ganzen Tag auf dem Sofa.",
    perfekt: ["perfekter", "perfekten", "perfekte", "perfektem"],
    perfekt_key: "perfekten",
    img: "man-on-sofa.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Urlaub (maskulin)",
      "Nominativ: der perfekte Urlaub",
      "Akkusativ: den perfekten Urlaub",
      "Dativ: dem perfekten Urlaub",
    ],
    hint_correct: 3,
    id: "31",
  },
  {
    text: "Für mich war es ein &perfekt Urlaub. Ich lag den ganzen Tag auf dem Sofa.",
    perfekt: ["perfekter", "perfekten", "perfekte", "perfektem"],
    perfekt_key: "perfekter",
    img: "man-on-sofa.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Urlaub (maskulin)",
      "Nominativ: ein perfekter Urlaub",
      "Akkusativ: einen perfekten Urlaub",
      "Dativ: einem perfekten Urlaub",
    ],
    hint_correct: 1,
    id: "32",
  },
  {
    text: "Ich hatte einen &perfekt Urlaub. Ich lag den ganzen Tag auf dem Sofa.",
    perfekt: ["perfekter", "perfekten", "perfekte", "perfektem"],
    perfekt_key: "perfekten",
    img: "man-on-sofa.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Urlaub (maskulin)",
      "Nominativ: ein perfekter Urlaub",
      "Akkusativ: einen perfekten Urlaub",
      "Dativ: einem perfekten Urlaub",
    ],
    hint_correct: 2,
    id: "33",
  },
  {
    text: "Ich war in einem &perfekt Urlaub.  Ich lag den ganzen Tag auf dem Sofa.",
    perfekt: ["perfekter", "perfekten", "perfekte", "perfektem"],
    perfekt_key: "perfekten",
    img: "man-on-sofa.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Urlaub (maskulin)",
      "Nominativ: ein perfekter Urlaub",
      "Akkusativ: einen perfekten Urlaub",
      "Dativ: einem perfekten Urlaub",
    ],
    hint_correct: 3,
    id: "34",
  },
  {
    text: "Der &dunkl Wald, in dem meine Oma lebt, ist ziemlich gefährlich. Aber ich muss zu Oma.",
    dunkl: ["dunkler", "dunklen", "dunkle", "dunklem"],
    dunkl_key: "dunkle",
    img: "red-hood.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Wald (maskulin)",
      "Nominativ: der dunkle Wald",
      "Akkusativ: den dunklen Wald",
      "Dativ: dem dunklen Wald",
    ],
    hint_correct: 1,
    id: "35",
  },
  {
    text: "Es ist ein ziemlich &dunkl Wald. Aber ich muss zu Oma.",
    dunkl: ["dunkler", "dunklen", "dunkle", "dunklem"],
    dunkl_key: "dunkler",
    img: "red-hood.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Wald (maskulin)",
      "Nominativ: ein dunkler Wald",
      "Akkusativ: einen dunklen Wald",
      "Dativ: einem dunklen Wald",
    ],
    hint_correct: 1,
    id: "36",
  },
  {
    text: "Es war nicht klug von mir, allein durch einen &dunkl Wald zu gehen.",
    dunkl: ["dunkler", "dunklen", "dunkle", "dunklem"],
    dunkl_key: "dunklen",
    img: "red-hood.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Wald (maskulin)",
      "Nominativ: ein dunkler Wald",
      "Akkusativ: einen dunklen Wald",
      "Dativ: einem dunklen Wald",
    ],
    hint_correct: 2,
    id: "37",
  },
  {
    text: "Warum lebt meine Oma in einem &dunkl Wald? Gute Frage!",
    dunkl: ["dunkler", "dunklen", "dunkle", "dunklem"],
    dunkl_key: "dunklen",
    img: "red-hood.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Wald (maskulin)",
      "Nominativ: ein dunkler Wald",
      "Akkusativ: einen dunklen Wald",
      "Dativ: einem dunklen Wald",
    ],
    hint_correct: 3,
    id: "38",
  },
  {
    text: "Das &komisch Haustier, das mein Freund gekauft hat, hat seinen Schuh gegessen.",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komische",
    img: "pet.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Haustier (neutral)",
      "Nominativ: das komische Haustier",
      "Akkusativ: das komische Haustier",
      "Dativ: dem komischen Haustier",
    ],
    hint_correct: 1,
    id: "39",
  },
  {
    text: "Mein Freund mag das &komisch Haustier, das er sich gekauft hat, sehr.",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komische",
    img: "pet.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Haustier (neutral)",
      "Nominativ: das komische Haustier",
      "Akkusativ: das komische Haustier",
      "Dativ: dem komischen Haustier",
    ],
    hint_correct: 2,
    id: "40",
  },
  {
    text: "Mein Freund ist sehr zufrieden mit dem &komisch Haustier, das er sich gekauft hat.",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komischen",
    img: "pet.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Haustier (neutral)",
      "Nominativ: das komische Haustier",
      "Akkusativ: das komische Haustier",
      "Dativ: dem komischen Haustier",
    ],
    hint_correct: 3,
    id: "41",
  },
  {
    text: "Es ist ein &komisch Haustier.",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komisches",
    img: "pet.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Haustier (neutral)",
      "Nominativ: ein komisches Haustier",
      "Akkusativ: ein komisches Haustier",
      "Dativ: einem komischen Haustier",
    ],
    hint_correct: 1,
    id: "42",
  },
  {
    text: "Mein Freund hat sich ein &komisch Haustier gekauft.",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komisches",
    img: "pet.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Haustier (neutral)",
      "Nominativ: ein komisches Haustier",
      "Akkusativ: ein komisches Haustier",
      "Dativ: einem komischen Haustier",
    ],
    hint_correct: 2,
    id: "43",
  },
  {
    text: "Ich sehe meinen Freund, der mit einem &komisch Haustier spazieren geht.",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komischen",
    img: "pet.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Haustier (neutral)",
      "Nominativ: ein komisches Haustier",
      "Akkusativ: ein komisches Haustier",
      "Dativ: einem komischen Haustier",
    ],
    hint_correct: 3,
    id: "44",
  },
  {
    text: "Die &komisch Haustiere, die mein Freund gekauft hat, haben seinen Schuh gegessen.",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komischen",
    img: "pets.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Haustier (plural)",
      "Nominativ: die komischen Haustiere",
      "Akkusativ: die komischen Haustiere",
      "Dativ: den komischen Haustieren",
    ],
    hint_correct: 1,
    id: "45",
  },
  {
    text: "Mein Freund mag die &komisch Haustiere, die er sich gekauft hat, sehr.",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komischen",
    img: "pets.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Haustier (plural)",
      "Nominativ: die komischen Haustiere",
      "Akkusativ: die komischen Haustiere",
      "Dativ: den komischen Haustieren",
    ],
    hint_correct: 2,
    id: "46",
  },
  {
    text: "Mein Freund ist sehr zufrieden mit den &komisch Haustieren, die er sich gekauft hat.",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komischen",
    img: "pets.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Haustier (plural)",
      "Nominativ: die komischen Haustiere",
      "Akkusativ: die komischen Haustiere",
      "Dativ: den komischen Haustieren",
    ],
    hint_correct: 3,
    id: "47",
  },
  {
    text: "Das sind sehr &komisch Haustiere.",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komische",
    img: "pets.svg",
    answered: 0,
    visited: false,
    hint: [
      "Haustier (plural)",
      "Nominativ: komische Haustiere",
      "Akkusativ: komische Haustiere",
      "Dativ: komischen Haustieren",
    ],
    hint_correct: 1,
    id: "48",
  },
  {
    text: "Mein Freund hat sich &komisch Haustiere gekauft. ",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komische",
    img: "pets.svg",
    answered: 0,
    visited: false,
    hint: [
      "Haustier (plural)",
      "Nominativ: komische Haustiere",
      "Akkusativ: komische Haustiere",
      "Dativ: komischen Haustieren",
    ],
    hint_correct: 2,
    id: "50",
  },
  {
    text: "Ich sehe meinen Freund, der mit &komisch Haustieren spazieren geht.",
    komisch: ["komischer", "komischen", "komische", "komisches"],
    komisch_key: "komischen",
    img: "pets.svg",
    answered: 0,
    visited: false,
    hint: [
      "Haustier (plural)",
      "Nominativ: komische Haustiere",
      "Akkusativ: komische Haustiere",
      "Dativ: komischen Haustieren",
    ],
    hint_correct: 3,
    id: "51",
  },
  {
    text: "Du hast zu lange gewartet. Die &groß Tasse Tee, die ich gemacht habe, ist bereits kalt.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "große",
    img: "cup-of-tea.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tasse (feminin)",
      "Nominativ: die große Tasse",
      "Akkusativ: die große Tasse",
      "Dativ: der großen Tasse",
    ],
    hint_correct: 1,
    id: "52",
  },
  {
    text: "Danke für die &groß Tasse Tee, die du für mich gemacht hast.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "große",
    img: "cup-of-tea.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tasse (feminin)",
      "Nominativ: die große Tasse",
      "Akkusativ: die große Tasse",
      "Dativ: der großen Tasse",
    ],
    hint_correct: 2,
    id: "53",
  },
  {
    text: "Mir ist kalt. Gerne wärme ich mich mit der &groß Tasse Tee auf, die du mir gemacht hast.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "großen",
    img: "cup-of-tea.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tasse (feminin)",
      "Nominativ: die große Tasse",
      "Akkusativ: die große Tasse",
      "Dativ: der großen Tasse",
    ],
    hint_correct: 3,
    id: "54",
  },
  {
    text: "Es ist eine &groß Tasse Tee.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "große",
    img: "cup-of-tea.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tasse (feminin)",
      "Nominativ: eine große Tasse",
      "Akkusativ: eine große Tasse",
      "Dativ: einer großen Tasse",
    ],
    hint_correct: 1,
    id: "55",
  },
  {
    text: "Ich möchte eine &groß Tasse Tee.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "große",
    img: "cup-of-tea.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tasse (feminin)",
      "Nominativ: eine große Tasse",
      "Akkusativ: eine große Tasse",
      "Dativ: einer großen Tasse",
    ],
    hint_correct: 2,
    id: "56",
  },
  {
    text: "Meinen Tag beginne ich mit einer &groß Tasse Tee.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "großen",
    img: "cup-of-tea.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tasse (feminin)",
      "Nominativ: eine große Tasse",
      "Akkusativ: eine große Tasse",
      "Dativ: einer großen Tasse",
    ],
    hint_correct: 3,
    id: "57",
  },
  {
    text: "Sie haben zu lange gewartet. Die &groß Tassen Tee, die ich gemacht habe, sind bereits kalt.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "großen",
    img: "two-cups.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tassen (plural)",
      "Nominativ: die großen Tassen",
      "Akkusativ: die großen Tassen",
      "Dativ: den großen Tassen",
    ],
    hint_correct: 1,
    id: "58",
  },
  {
    text: "Danke für die &groß Tassen Tee, die du für uns gemacht hast.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "großen",
    img: "two-cups-two-people.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tassen (plural)",
      "Nominativ: die großen Tassen",
      "Akkusativ: die großen Tassen",
      "Dativ: den großen Tassen",
    ],
    hint_correct: 2,
    id: "59",
  },
  {
    text: "Uns ist kalt. Gerne wärmen wir uns mit den &groß Tassen Tee auf, die du uns gemacht hast.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "großen",
    img: "two-cups-two-people.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tassen (plural)",
      "Nominativ: die großen Tassen",
      "Akkusativ: die großen Tassen",
      "Dativ: den großen Tassen",
    ],
    hint_correct: 3,
    id: "60",
  },
  {
    text: "Das sind wirklich &groß Tassen Tee.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "große",
    img: "two-cups.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tassen (plural)",
      "Nominativ: große Tassen",
      "Akkusativ: große Tassen",
      "Dativ: großen Tassen",
    ],
    hint_correct: 1,
    id: "61",
  },
  {
    text: "Wir möchten zwei &groß Tassen Tee.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "große",
    img: "two-cups-two-people.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tassen (plural)",
      "Nominativ: große Tassen",
      "Akkusativ: große Tassen",
      "Dativ: großen Tassen",
    ],
    hint_correct: 2,
    id: "62",
  },
  {
    text: "Wir möchten den Tag mit zwei &groß Tassen Tee beginnen.",
    groß: ["großer", "großen", "große", "großem"],
    groß_key: "großen",
    img: "two-cups-two-people.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Tassen (plural)",
      "Nominativ: große Tassen",
      "Akkusativ: große Tassen",
      "Dativ: großen Tassen",
    ],
    hint_correct: 3,
    id: "63",
  },
  {
    text: "Das ist der &gefälscht Ausweis, den ich gestern gefunden habe.",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschte",
    img: "abama.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Ausweis (maskuline)",
      "Nominativ: der gefälschte Ausweis",
      "Akkusativ: den gefälschten Ausweis",
      "Dativ: dem gefälschten Ausweis",
    ],
    hint_correct: 1,
    id: "64",
  },
  {
    text: "Ich habe den &gefälscht Ausweis, den ich gestern gefunden habe, zur Polizei gebracht.",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschten",
    img: "abama.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Ausweis (maskuline)",
      "Nominativ: der gefälschte Ausweis",
      "Akkusativ: den gefälschten Ausweis",
      "Dativ: dem gefälschten Ausweis",
    ],
    hint_correct: 2,
    id: "65",
  },
  {
    text: "Mit dem &gefälscht Ausweis, den du selbst gemacht hast, kannst du im Gefängnis landen.",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschten",
    img: "abama.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Ausweis (maskuline)",
      "Nominativ: der gefälschte Ausweis",
      "Akkusativ: den gefälschten Ausweis",
      "Dativ: dem gefälschten Ausweis",
    ],
    hint_correct: 3,
    id: "66",
  },
  {
    text: "Es ist wahrscheinlich ein &gefälscht Ausweis.",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschter",
    img: "abama.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Ausweis (maskuline)",
      "Nominativ: ein gefälschter Ausweis",
      "Akkusativ: einen gefälschten Ausweis",
      "Dativ: einem gefälschten Ausweis",
    ],
    hint_correct: 1,
    id: "67",
  },
  {
    text: "Die Polizei hat einen &gefälscht Ausweis gefunden.",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschten",
    img: "abama.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Ausweis (maskuline)",
      "Nominativ: ein gefälschter Ausweis",
      "Akkusativ: einen gefälschten Ausweis",
      "Dativ: einem gefälschten Ausweis",
    ],
    hint_correct: 2,
    id: "68",
  },
  {
    text: "Die Polizei hat einen Mann mit einem &gefälscht Ausweis festgenommen. ",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschten",
    img: "abama.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Ausweis (maskuline)",
      "Nominativ: ein gefälschter Ausweis",
      "Akkusativ: einen gefälschten Ausweis",
      "Dativ: einem gefälschten Ausweis",
    ],
    hint_correct: 3,
    id: "69",
  },
  {
    text: "Das sind die &gefälscht Ausweise, die ich gestern gefunden habe.",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschten",
    img: "abama-mercel.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Ausweise (plural)",
      "Nominativ: die gefälschten Ausweise",
      "Akkusativ: die gefälschten Ausweise",
      "Dativ: den gefälschten Ausweisen",
    ],
    hint_correct: 1,
    id: "70",
  },
  {
    text: "Ich habe die &gefälscht Ausweise, die ich gestern gefunden habe, zur Polizei gebracht.",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschten",
    img: "abama-mercel.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Ausweise (plural)",
      "Nominativ: die gefälschten Ausweise",
      "Akkusativ: die gefälschten Ausweise",
      "Dativ: den gefälschten Ausweisen",
    ],
    hint_correct: 2,
    id: "71",
  },
  {
    text: "Mit den &gefälscht Ausweisen, die du selbst gemacht hast, kannst du im Gefängnis landen.",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschten",
    img: "abama-mercel.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Ausweise (plural)",
      "Nominativ: die gefälschten Ausweise",
      "Akkusativ: die gefälschten Ausweise",
      "Dativ: den gefälschten Ausweisen",
    ],
    hint_correct: 3,
    id: "72",
  },
  {
    text: "Ich glaube, das sind &gefälscht Ausweise.",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschte",
    img: "abama-mercel.svg",
    answered: 0,
    visited: false,
    hint: [
      "Ausweise (plural)",
      "Nominativ: gefälschte Ausweise",
      "Akkusativ: gefälschte Ausweise",
      "Dativ: gefälschten Ausweisen",
    ],
    hint_correct: 1,
    id: "73",
  },
  {
    text: "Die Polizei hat &gefälscht Ausweise gefunden.",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschte",
    img: "abama-mercel.svg",
    answered: 0,
    visited: false,
    hint: [
      "Ausweise (plural)",
      "Nominativ: gefälschte Ausweise",
      "Akkusativ: gefälschte Ausweise",
      "Dativ: gefälschten Ausweisen",
    ],
    hint_correct: 2,
    id: "74",
  },
  {
    text: "Die Polizei hat einen Mann mit &gefälscht Ausweisen festgenommen. ",
    gefälscht: ["gefälschter", "gefälschten", "gefälschte", "gefälschtem"],
    gefälscht_key: "gefälschten",
    img: "abama-mercel.svg",
    answered: 0,
    visited: false,
    hint: [
      "Ausweise (plural)",
      "Nominativ: gefälschte Ausweise",
      "Akkusativ: gefälschte Ausweise",
      "Dativ: gefälschten Ausweisen",
    ],
    hint_correct: 3,
    id: "75",
  },
  {
    text: "Das ist die &einfach Blume, die ich zum Geburtstag bekommen habe.",
    einfach: ["einfacher", "einfachen", "einfache", "einfachem"],
    einfach_key: "einfache",
    img: "flower.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Blume (feminine)",
      "Nominativ: die einfache Blume",
      "Akkusativ: die einfache Blume",
      "Dativ: der einfachen Blume",
    ],
    hint_correct: 1,
    id: "76",
  },
  {
    text: "Ich möchte die &einfach Blume wegwerfen, die er mir zum Geburtstag geschenkt hat.",
    einfach: ["einfacher", "einfachen", "einfache", "einfachem"],
    einfach_key: "einfache",
    img: "flower.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Blume (feminine)",
      "Nominativ: die einfache Blume",
      "Akkusativ: die einfache Blume",
      "Dativ: der einfachen Blume",
    ],
    hint_correct: 2,
    id: "77",
  },
  {
    text: "Es ist nur eine &einfach Blume. Und ich wollte einen großen Strauß zu meinem Geburtstag.",
    einfach: ["einfacher", "einfachen", "einfache", "einfachem"],
    einfach_key: "einfache",
    img: "flower.svg",
    answered: 0,
    visited: false,
    hint: [
      "eine Blume (feminine)",
      "Nominativ: eine einfache Blume",
      "Akkusativ: eine einfache Blume",
      "Dativ: einer einfachen Blume",
    ],
    hint_correct: 1,
    id: "78",
  },
  {
    text: "Zu meinem Geburtstag hat er mir nur eine &einfach Blume geschenkt.",
    einfach: ["einfacher", "einfachen", "einfache", "einfachem"],
    einfach_key: "einfache",
    img: "flower.svg",
    answered: 0,
    visited: false,
    hint: [
      "eine Blume (feminine)",
      "Nominativ: eine einfache Blume",
      "Akkusativ: eine einfache Blume",
      "Dativ: einer einfachen Blume",
    ],
    hint_correct: 2,
    id: "79",
  },
  {
    text: "Er ist zu meinem Geburtstag nur mit einer &einfach Blume gekommen.",
    einfach: ["einfacher", "einfachen", "einfache", "einfachem"],
    einfach_key: "einfachen",
    img: "flower.svg",
    answered: 0,
    visited: false,
    hint: [
      "eine Blume (feminine)",
      "Nominativ: eine einfache Blume",
      "Akkusativ: eine einfache Blume",
      "Dativ: einer einfachen Blume",
    ],
    hint_correct: 3,
    id: "80",
  },
  {
    text: "Das sind die &einfach Blumen, die ich zum Geburtstag bekommen habe.",
    einfach: ["einfacher", "einfachen", "einfache", "einfachem"],
    einfach_key: "einfachen",
    img: "flowers.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Blumen (plural)",
      "Nominativ: die einfachen Blumen",
      "Akkusativ: die einfachen Blumen",
      "Dativ: den einfachen Blumen",
    ],
    hint_correct: 1,
    id: "81",
  },
  {
    text: "Ich möchte die &einfach Blumen wegwerfen, die er mir zum Geburtstag geschenkt hat.",
    einfach: ["einfacher", "einfachen", "einfache", "einfachem"],
    einfach_key: "einfachen",
    img: "flowers.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Blumen (plural)",
      "Nominativ: die einfachen Blumen",
      "Akkusativ: die einfachen Blumen",
      "Dativ: den einfachen Blumen",
    ],
    hint_correct: 2,
    id: "82",
  },
  {
    text: "Es sind nur &einfach Blumen. Und ich wollte einen großen Strauß zu meinem Geburtstag.",
    einfach: ["einfacher", "einfachen", "einfache", "einfachem"],
    einfach_key: "einfache",
    img: "flowers.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Blumen (plural)",
      "Nominativ: einfache Blumen",
      "Akkusativ: einfache Blumen",
      "Dativ: einfachen Blumen",
    ],
    hint_correct: 1,
    id: "83",
  },
  {
    text: "Zu meinem Geburtstag hat er mir nur &einfach Blumen geschenkt.",
    einfach: ["einfacher", "einfachen", "einfache", "einfachem"],
    einfach_key: "einfache",
    img: "flowers.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Blumen (plural)",
      "Nominativ: einfache Blumen",
      "Akkusativ: einfache Blumen",
      "Dativ: einfachen Blumen",
    ],
    hint_correct: 2,
    id: "83",
  },
  {
    text: "Er ist zu meinem Geburtstag nur mit &einfach Blumen gekommen.",
    einfach: ["einfacher", "einfachen", "einfache", "einfachem"],
    einfach_key: "einfachen",
    img: "flowers.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Blumen (plural)",
      "Nominativ: einfache Blumen",
      "Akkusativ: einfache Blumen",
      "Dativ: einfachen Blumen",
    ],
    hint_correct: 3,
    id: "84",
  },
  {
    text: "Es ist der &alt Schuh, den ich in meinem Kleiderschrank gefunden habe.",
    alt: ["alter", "alten", "alte", "altem"],
    alt_key: "alte",
    img: "shoe.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Schuh (maskulin)",
      "Nominativ: der alte Schuh",
      "Akkusativ: den alten Schuh",
      "Dativ: dem alten Schuh",
    ],
    hint_correct: 1,
    id: "85",
  },
  {
    text: "Ich mag den &alt Schuh, den ich in meinem Schrank gefunden habe.",
    alt: ["alter", "alten", "alte", "altem"],
    alt_key: "alten",
    img: "shoe.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Schuh (maskulin)",
      "Nominativ: der alte Schuh",
      "Akkusativ: den alten Schuh",
      "Dativ: dem alten Schuh",
    ],
    hint_correct: 2,
    id: "86",
  },
  {
    text: "In dem &alt Schuh, den ich in meinem Schrank gefunden habe, kann ich immer noch schick aussehen.",
    alt: ["alter", "alten", "alte", "altem"],
    alt_key: "alten",
    img: "shoe.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Schuh (maskulin)",
      "Nominativ: der alte Schuh",
      "Akkusativ: den alten Schuh",
      "Dativ: dem alten Schuh",
    ],
    hint_correct: 3,
    id: "87",
  },
  {
    text: "Es ist ein ziemlich &alt Schuh.",
    alt: ["alter", "alten", "alte", "altem"],
    alt_key: "alter",
    img: "shoe.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Schuh (maskulin)",
      "Nominativ: ein alter Schuh",
      "Akkusativ: einen alten Schuh",
      "Dativ: einem alten Schuh",
    ],
    hint_correct: 1,
    id: "88",
  },
  {
    text: "Ich glaube, ich brauche einen &neu Schuh.",
    neu: ["neuer", "neuen", "neue", "neuem"],
    neu_key: "neuen",
    img: "shoe.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Schuh (maskulin)",
      "Nominativ: ein neuer Schuh",
      "Akkusativ: einen neuen Schuh",
      "Dativ: einem neuem Schuh",
    ],
    hint_correct: 2,
    id: "89",
  },
  {
    text: "In einem &alt Schuh kann ich immer noch schick aussehen.",
    alt: ["alter", "alten", "alte", "altem"],
    alt_key: "alten",
    img: "shoe.svg",
    answered: 0,
    visited: false,
    hint: [
      "der Schuh (maskulin)",
      "Nominativ: ein alter Schuh",
      "Akkusativ: einen alten Schuh",
      "Dativ: einem alten Schuh",
    ],
    hint_correct: 3,
    id: "90",
  },
  {
    text: "Das sind die &alt Schuhe, die ich in meinem Kleiderschrank gefunden habe.",
    alt: ["alter", "alten", "alte", "altem"],
    alt_key: "alten",
    img: "shoes.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Schuhe (plural)",
      "Nominativ: die alten Schuhe",
      "Akkusativ: die alten Schuhe",
      "Dativ: den alten Schuhe",
    ],
    hint_correct: 1,
    id: "91",
  },
  {
    text: "Ich mag die &alt Schuhe, die ich in meinem Kleiderschrank gefunden habe.",
    alt: ["alter", "alten", "alte", "altem"],
    alt_key: "alten",
    img: "shoes.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Schuhe (plural)",
      "Nominativ: die alten Schuhe",
      "Akkusativ: die alten Schuhe",
      "Dativ: den alten Schuhe",
    ],
    hint_correct: 2,
    id: "92",
  },
  {
    text: "In den &alt Schuhen, die ich in meinem Schrank gefunden habe, kann ich immer noch schick aussehen.",
    alt: ["alter", "alten", "alte", "altem"],
    alt_key: "alten",
    img: "shoes.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Schuhe (plural)",
      "Nominativ: die alten Schuhe",
      "Akkusativ: die alten Schuhe",
      "Dativ: den alten Schuhe",
    ],
    hint_correct: 3,
    id: "93",
  },
  {
    text: "Das sind wirklich &alt Schuhe.",
    alt: ["alter", "alten", "alte", "altem"],
    alt_key: "alte",
    img: "shoes.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Schuhe (plural)",
      "Nominativ: alte Schuhe",
      "Akkusativ: alte Schuhe",
      "Dativ: alten Schuhe",
    ],
    hint_correct: 1,
    id: "94",
  },
  {
    text: "Ich glaube, ich brauche &neu Schuhe. ",
    neu: ["neuer", "neuen", "neue", "neuem"],
    neu_key: "neue",
    img: "shoes.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Schuhe (plural)",
      "Nominativ: neue Schuhe",
      "Akkusativ: neue Schuhe",
      "Dativ: neuen Schuhe",
    ],
    hint_correct: 2,
    id: "95",
  },
  {
    text: "In &alt Schuhen kann ich immer noch schick aussehen.",
    alt: ["alter", "alten", "alte", "altem"],
    alt_key: "alten",
    img: "shoes.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Schuhe (plural)",
      "Nominativ: alte Schuhe",
      "Akkusativ: alte Schuhe",
      "Dativ: alten Schuhe",
    ],
    hint_correct: 3,
    id: "96",
  },
  {
    text: "Es ist die &vegetarisch Suppe, die ich für dich gemacht habe. Und du hast einen Burger gegessen!",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarische",
    img: "soup-man-sad.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Suppe (feminin)",
      "Nominativ: die vegetarische Suppe",
      "Akkusativ: die vegetarische Suppe",
      "Dativ: der vegetarischen Suppe",
    ],
    hint_correct: 1,
    id: "97",
  },
  {
    text: "Ich möchte die &vegetarisch Suppe, die du für mich gemacht hast. Ich will keinen Burger!",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarische",
    img: "soup-man-happy.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Suppe (feminin)",
      "Nominativ: die vegetarische Suppe",
      "Akkusativ: die vegetarische Suppe",
      "Dativ: der vegetarischen Suppe",
    ],
    hint_correct: 2,
    id: "98",
  },
  {
    text: "Mit der &vegetarisch Suppe, die du gemacht hast, werde ich nicht satt. Ich will einen Burger!",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarischen",
    img: "soup-man-sad.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Suppe (feminin)",
      "Nominativ: die vegetarische Suppe",
      "Akkusativ: die vegetarische Suppe",
      "Dativ: der vegetarischen Suppe",
    ],
    hint_correct: 3,
    id: "99",
  },
  {
    text: "Es ist eine &vegetarisch Suppe. Aber ich will einen Burger!",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarische",
    img: "soup-man-sad.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Suppe (feminin)",
      "Nominativ: eine vegetarische Suppe",
      "Akkusativ: eine vegetarische Suppe",
      "Dativ: einer vegetarischen Suppe",
    ],
    hint_correct: 1,
    id: "100",
  },
  {
    text: "Ich möchte eine &vegetarisch Suppe. Ich will keinen Burger.",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarische",
    img: "soup-man-happy.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Suppe (feminin)",
      "Nominativ: eine vegetarische Suppe",
      "Akkusativ: eine vegetarische Suppe",
      "Dativ: einer vegetarischen Suppe",
    ],
    hint_correct: 2,
    id: "101",
  },
  {
    text: "Mit einer &vegetarisch Suppe werde ich nicht satt. Ich will einen Burger!",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarischen",
    img: "soup-man-sad.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Suppe (feminin)",
      "Nominativ: eine vegetarische Suppe",
      "Akkusativ: eine vegetarische Suppe",
      "Dativ: einer vegetarischen Suppe",
    ],
    hint_correct: 3,
    id: "102",
  },
  {
    text: "Das sind die &vegetarisch Suppen, die sie anbieten. Jede von ihnen ist besser als ein Burger.",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarischen",
    img: "soups-happy.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Suppen (plural)",
      "Nominativ: die vegetarischen Suppen",
      "Akkusativ: die vegetarischen Suppen",
      "Dativ: den vegetarischen Suppen",
    ],
    hint_correct: 1,
    id: "103",
  },
  {
    text: "Schauen Sie sich die &vegetarisch Suppen an, die sie anbieten. Jede von ihnen ist besser als ein Burger.",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarischen",
    img: "soups-happy.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Suppen (plural)",
      "Nominativ: die vegetarischen Suppen",
      "Akkusativ: die vegetarischen Suppen",
      "Dativ: den vegetarischen Suppen",
    ],
    hint_correct: 2,
    id: "104",
  },
  {
    text: "Dies sind &vegetarisch Suppen. Aber ich will einen Burger!",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarische",
    img: "soups-sad.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Suppen (plural)",
      "Nominativ: vegetarische Suppen",
      "Akkusativ: vegetarische Suppen",
      "Dativ: vegetarischen Suppen",
    ],
    hint_correct: 1,
    id: "105",
  },
  {
    text: "Ich mag &vegetarisch Suppen. Ich esse keine Burger!",
    vegetarisch: [
      "vegetarischer",
      "vegetarischen",
      "vegetarische",
      "vegetarischem",
    ],
    vegetarisch_key: "vegetarische",
    img: "soups-happy.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Suppen (plural)",
      "Nominativ: vegetarische Suppen",
      "Akkusativ: vegetarische Suppen",
      "Dativ: vegetarischen Suppen",
    ],
    hint_correct: 2,
    id: "106",
  },
  {
    text: "Dies ist die &nützlich Pflanze, die ich selbst zu Hause angebaut habe.",
    nützlich: ["nützlicher", "nützlichen", "nützliche", "nützlichem"],
    nützlich_key: "nützliche",
    img: "plant.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanze (feminin)",
      "Nominativ: die nützliche Pflanze",
      "Akkusativ: die nützliche Pflanze",
      "Dativ: der nützlichen Pflanze",
    ],
    hint_correct: 1,
    id: "107",
  },
  {
    text: "Ich finde die &neu Pflanze, die ich zu Hause angebaut habe, sehr nützlich.",
    neu: ["neuer", "neuen", "neue", "neuem"],
    neu_key: "neue",
    img: "plant.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanze (feminin)",
      "Nominativ: die neue Pflanze",
      "Akkusativ: die neue Pflanze",
      "Dativ: der neuen Pflanze",
    ],
    hint_correct: 2,
    id: "108",
  },
  {
    text: "Mit der &neu Pflanze, die ich zu Hause selbst angebaut habe, sieht das Zimmer viel fröhlicher aus.",
    neu: ["neuer", "neuen", "neue", "neuem"],
    neu_key: "neuen",
    img: "plant.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanze (feminin)",
      "Nominativ: die neue Pflanze",
      "Akkusativ: die neue Pflanze",
      "Dativ: der neuen Pflanze",
    ],
    hint_correct: 3,
    id: "109",
  },
  {
    text: "Das ist eine &nützlich Pflanze!",
    nützlich: ["nützlicher", "nützlichen", "nützliche", "nützlichem"],
    nützlich_key: "nützliche",
    img: "plant.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanze (feminin)",
      "Nominativ: eine nützliche Pflanze",
      "Akkusativ: eine nützliche Pflanze",
      "Dativ: einer nützlichen Pflanze",
    ],
    hint_correct: 1,
    id: "110",
  },
  {
    text: "Ich möchte eine &nützlich Pflanze anbauen.",
    nützlich: ["nützlicher", "nützlichen", "nützliche", "nützlichem"],
    nützlich_key: "nützliche",
    img: "plant.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanze (feminin)",
      "Nominativ: eine nützliche Pflanze",
      "Akkusativ: eine nützliche Pflanze",
      "Dativ: einer nützlichen Pflanze",
    ],
    hint_correct: 2,
    id: "111",
  },
  {
    text: "Ich habe mein Zimmer mit einer &nützlich Pflanze dekoriert.",
    nützlich: ["nützlicher", "nützlichen", "nützliche", "nützlichem"],
    nützlich_key: "nützlichen",
    img: "plant.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanze (feminin)",
      "Nominativ: eine nützliche Pflanze",
      "Akkusativ: eine nützliche Pflanze",
      "Dativ: einer nützlichen Pflanze",
    ],
    hint_correct: 3,
    id: "112",
  },
  {
    text: "Dies sind die &nützlich Pflanzen, die ich selbst zu Hause angebaut habe.",
    nützlich: ["nützlicher", "nützlichen", "nützliche", "nützlichem"],
    nützlich_key: "nützlichen",
    img: "plants.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanzen (plural)",
      "Nominativ: die nützlichen Pflanzen",
      "Akkusativ: die nützlichen Pflanzen",
      "Dativ: den nützlichen Pflanzen",
    ],
    hint_correct: 1,
    id: "113",
  },
  {
    text: "Ich finde die &neu Pflanzen, die ich zu Hause angebaut habe, sehr nützlich.",
    neu: ["neuer", "neuen", "neue", "neuem"],
    neu_key: "neuen",
    img: "plants.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanzen (plural)",
      "Nominativ: die neuen Pflanzen",
      "Akkusativ: die neuen Pflanzen",
      "Dativ: den neuen Pflanzen",
    ],
    hint_correct: 2,
    id: "114",
  },
  {
    text: "Mit den &neu Pflanzen, die ich zu Hause selbst angebaut habe, sieht das Zimmer viel fröhlicher aus",
    neu: ["neuer", "neuen", "neue", "neuem"],
    neu_key: "neuen",
    img: "plants.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanzen (plural)",
      "Nominativ: die neuen Pflanzen",
      "Akkusativ: die neuen Pflanzen",
      "Dativ: den neuen Pflanzen",
    ],
    hint_correct: 3,
    id: "115",
  },
  {
    text: "Dies sind &nützlich Pflanzen!",
    nützlich: ["nützlicher", "nützlichen", "nützliche", "nützlichem"],
    nützlich_key: "nützliche",
    img: "plants.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanzen (plural)",
      "Nominativ: nützliche Pflanzen",
      "Akkusativ: nützliche Pflanzen",
      "Dativ: nützlichen Pflanzen",
    ],
    hint_correct: 1,
    id: "116",
  },
  {
    text: "Ich möchte &nützlich Pflanzen anbauen.",
    nützlich: ["nützlicher", "nützlichen", "nützliche", "nützlichem"],
    nützlich_key: "nützliche",
    img: "plants.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanzen (plural)",
      "Nominativ: nützliche Pflanzen",
      "Akkusativ: nützliche Pflanzen",
      "Dativ: nützlichen Pflanzen",
    ],
    hint_correct: 2,
    id: "117",
  },
  {
    text: "Ich habe mein Zimmer mit &nützlich Pflanzen dekoriert. ",
    nützlich: ["nützlicher", "nützlichen", "nützliche", "nützlichem"],
    nützlich_key: "nützlichen",
    img: "plants.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Pflanzen (plural)",
      "Nominativ: nützliche Pflanzen",
      "Akkusativ: nützliche Pflanzen",
      "Dativ: nützlichen Pflanzen",
    ],
    hint_correct: 3,
    id: "118",
  },
  {
    text: "Es ist das &schmutzig Hemd, das ich auf der Party getragen habe.",
    schmutzig: ["schmutziger", "schmutzigen", "schmutzige", "schmutzigem"],
    schmutzig_key: "schmutzige",
    img: "dirty-shirt.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Hemd (neutral)",
      "Nominativ: das schmutzige Hemd",
      "Akkusativ: das schmutzige Hemd",
      "Dativ: dem schmutzigen Hemd",
    ],
    hint_correct: 1,
    id: "119",
  },
  {
    text: "Ich habe nur das &schmutzig Hemd, das ich auf der Party getragen habe.",
    schmutzig: ["schmutziger", "schmutzigen", "schmutzige", "schmutzigem"],
    schmutzig_key: "schmutzige",
    img: "dirty-shirt.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Hemd (neutral)",
      "Nominativ: das schmutzige Hemd",
      "Akkusativ: das schmutzige Hemd",
      "Dativ: dem schmutzigen Hemd",
    ],
    hint_correct: 2,
    id: "120",
  },
  {
    text: "Ich kann nicht in dem &schmutzig Hemd zur Hochzeit gehen, das ich auf einer Party getragen habe.",
    schmutzig: ["schmutziger", "schmutzigen", "schmutzige", "schmutzigem"],
    schmutzig_key: "schmutzigen",
    img: "dirty-shirt.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Hemd (neutral)",
      "Nominativ: das schmutzige Hemd",
      "Akkusativ: das schmutzige Hemd",
      "Dativ: dem schmutzigen Hemd",
    ],
    hint_correct: 3,
    id: "121",
  },
  {
    text: "Es ist ein &schmutzig Hemd.",
    schmutzig: ["schmutziger", "schmutzigen", "schmutziges", "schmutzige"],
    schmutzig_key: "schmutziges",
    img: "dirty-shirt.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Hemd (neutral)",
      "Nominativ: ein schmutziges Hemd",
      "Akkusativ: ein schmutziges Hemd",
      "Dativ: einem schmutzigen Hemd",
    ],
    hint_correct: 1,
    id: "122",
  },
  {
    text: "Ich habe nur ein &schmutzig Hemd.",
    schmutzig: ["schmutziger", "schmutzigen", "schmutziges", "schmutzigen"],
    schmutzig_key: "schmutziges",
    img: "dirty-shirt.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Hemd (neutral)",
      "Nominativ: ein schmutziges Hemd",
      "Akkusativ: ein schmutziges Hemd",
      "Dativ: einem schmutzigen Hemd",
    ],
    hint_correct: 2,
    id: "123",
  },
  {
    text: "Ich kann nicht in einem &schmutzig Hemd zur Hochzeit gehen!",
    schmutzig: ["schmutziger", "schmutzigen", "schmutzige", "schmutzigem"],
    schmutzig_key: "schmutzigen",
    img: "dirty-shirt.svg",
    answered: 0,
    visited: false,
    hint: [
      "das Hemd (neutral)",
      "Nominativ: ein schmutziges Hemd",
      "Akkusativ: ein schmutziges Hemd",
      "Dativ: einem schmutzigen Hemd",
    ],
    hint_correct: 3,
    id: "124",
  },
  {
    text: "Dies sind die &schmutzig Hemden, die wir auf der Party getragen haben.",
    schmutzig: ["schmutziger", "schmutzigen", "schmutzige", "schmutzigem"],
    schmutzig_key: "schmutzigen",
    img: "dirty-shirts.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Hemden (plural)",
      "Nominativ: die schmutzigen Hemden",
      "Akkusativ: die schmutzigen Hemden",
      "Dativ: den schmutzigen Hemden",
    ],
    hint_correct: 1,
    id: "125",
  },
  {
    text: "Wir haben nur die &schmutzig Hemden, die wir auf der Hochzeit getragen haben.",
    schmutzig: ["schmutziger", "schmutzigen", "schmutzige", "schmutzigem"],
    schmutzig_key: "schmutzigen",
    img: "dirty-shirts.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Hemden (plural)",
      "Nominativ: die schmutzigen Hemden",
      "Akkusativ: die schmutzigen Hemden",
      "Dativ: den schmutzigen Hemden",
    ],
    hint_correct: 2,
    id: "126",
  },
  {
    text: "Wir können nicht in den &schmutzig Hemden zur Hochzeit gehen, die wir auf einer Party getragen haben.",
    schmutzig: ["schmutziger", "schmutzigen", "schmutzige", "schmutzigem"],
    schmutzig_key: "schmutzigen",
    img: "dirty-shirts.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Hemden (plural)",
      "Nominativ: die schmutzigen Hemden",
      "Akkusativ: die schmutzigen Hemden",
      "Dativ: den schmutzigen Hemden",
    ],
    hint_correct: 3,
    id: "127",
  },
  {
    text: "Dies sind &schmutzig Hemden!",
    schmutzig: ["schmutziger", "schmutzigen", "schmutzige", "schmutzigem"],
    schmutzig_key: "schmutzige",
    img: "dirty-shirts.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Hemden (plural)",
      "Nominativ: schmutzige Hemden",
      "Akkusativ: schmutzige Hemden",
      "Dativ: schmutzigen Hemden",
    ],
    hint_correct: 1,
    id: "128",
  },
  {
    text: "Wir haben nur &schmutzig Hemden!",
    schmutzig: ["schmutziger", "schmutzigen", "schmutzige", "schmutzigem"],
    schmutzig_key: "schmutzige",
    img: "dirty-shirts.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Hemden (plural)",
      "Nominativ: schmutzige Hemden",
      "Akkusativ: schmutzige Hemden",
      "Dativ: schmutzigen Hemden",
    ],
    hint_correct: 2,
    id: "129",
  },
  {
    text: "Wir können nicht in &schmutzig Hemden zur Hochzeit gehen!",
    schmutzig: ["schmutziger", "schmutzigen", "schmutzige", "schmutzigem"],
    schmutzig_key: "schmutzigen",
    img: "dirty-shirts.svg",
    answered: 0,
    visited: false,
    hint: [
      "die Hemden (plural)",
      "Nominativ: schmutzige Hemden",
      "Akkusativ: schmutzige Hemden",
      "Dativ: schmutzigen Hemden",
    ],
    hint_correct: 3,
    id: "130",
  },
  // {
  //   text: "Die Polizisten haben &eine &gefälschte Ausweis gefunden.",
  //   eine: ["einen", "einem", "eine"],
  //   eine_key: "einen",
  //   gefälschte: ["gefälschte", "gefälschtes", "gefälschten"],
  //   gefälschte_key: "gefälschten",
  //   img: "obama-ausweis.svg",
  //   answered: 0, // 0 - not asked, 1 - correct, 2 - failed
  //   visited: false,
  //   hint: [
  //     "der Ausweis (Maskulin)",
  //     "Nominativ: ein gefälschter Ausweis",
  //     "Akkusativ: einen gefälschten Ausweis",
  //     "Dativ: einem gefälschten Ausweis",
  //   ],
  //   hint_correct: 2,
  // },
  // {
  //   text: "Ich habe das mit &meine &eigene Augen gesehen!",
  //   meine: ["meinen", "meine", "meines", "meinem"],
  //   meine_key: "meinen",
  //   eigene: ["eigenen", "eigenes", "eigener", "eigenem"],
  //   eigene_key: "eigenen",
  //   img: "augen.svg",
  //   answered: false, // 0 - not asked, 1 - correct, 2 - failed
  //   visited: false,
  //   hint: [
  //     "Augen (Plural)",
  //     "Nominativ: meine eigenen Augen",
  //     "Akkusativ: meinen eigenen Augen",
  //     "Dativ: meinen eigenen Augen",
  //   ],
  //   hint_correct: 3,
  //   hint_used: false,
  // },
  // {
  //   text: "Ich habe das mit &meine &eigene Auge gesehen!",
  //   meine: ["meinen", "meine", "meines", "meinem"],
  //   meine_key: "meinem",
  //   eigene: ["eigenen", "eigenes", "eigener", "eigenem"],
  //   eigene_key: "eigenen",
  //   img: "auge.svg",
  //   answered: false, // 0 - not asked, 1 - correct, 2 - failed
  //   visited: false,
  //   hint: [
  //     "das Auge (Neuter)",
  //     "Nominativ: mein eigenes Auge",
  //     "Akkusativ: mein eigenes Auge",
  //     "Dativ: meinem eigenen Auge",
  //   ],
  //   hint_correct: 3,
  //   hint_used: false,
  // },
  // {
  //   text: "In &seine &letzte Buch gibt es viele Fehler",
  //   seine: ["seinen", "seinem", "seine", "seiner"],
  //   seine_key: "seinem",
  //   letzte: ["letzte", "letzter", "letzten", "letztem"],
  //   letzte_key: "letzten",
  //   img: "open-book.svg",
  //   answered: false, // 0 - not asked, 1 - correct, 2 - failed
  //   visited: false,
  //   hint: [
  //     "das Buch (Neuter)",
  //     "Nominativ: sein letztes Buch",
  //     "Akkusativ: sein letztes Buch",
  //     "Dativ: seinem letzten Buch",
  //   ],
  //   hint_correct: 3,
  //   hint_used: false,
  // },
  // {
  //   text: "Du kannst &deine &kaputte Computer nicht in den Müll werfen",
  //   deine: ["deiner", "deinen", "deinem", "deine"],
  //   deine_key: "deinen",
  //   kaputte: ["kaputte", "kaputten", "kaputter", "kaputtem"],
  //   kaputte_key: "kaputten",
  //   img: "dumpster.svg",
  //   answered: false, // 0 - not asked, 1 - correct, 2 - failed
  //   visited: false,
  //   hint: [
  //     "der Computer (Maskulin)",
  //     "Nominativ: dein kaputter Computer",
  //     "Akkusativ: deinen kaputten Computer",
  //     "Dativ: deinem kaputten Computer",
  //   ],
  //   hint_correct: 2,
  //   hint_used: false,
  // },
  // {
  //   text: "Ich habe ein schönes Foto auf &mein &neu Ausweis.",
  //   mein: ["meinen", "meinem", "meine"],
  //   mein_key: "meinem",
  //   neu: ["neue", "neues", "neuen"],
  //   neu_key: "neuen",
  //   img: "ausweis.svg",
  //   answered: 0, // 0 - not asked, 1 - correct, 2 - failed
  //   visited: false,
  //   hint: [
  //     "Der Ausweis (Maskulin)",
  //     "Nominativ: mein neuer Ausweis",
  //     "Akkusativ: meinen neuen Ausweis",
  //     "Dativ: meinem neuen Ausweis",
  //   ],
  //   hint_correct: 3,
  //   hint_used: false,
  // },
  // {
  //   text: "Ich werde sein &neue Buch lesen, wenn er meines liest.",
  //   neue: ["neue", "neuen", "neues"],
  //   neue_key: "neues",
  //   img: "readers.svg",
  //   answered: 0, // 0 - not asked, 1 - correct, 2 - failed
  //   visited: false,
  //   hint: [
  //     "Das Buch (Neuter)",
  //     "Nominativ: sein neues Buch",
  //     "Akkusativ: sein neues Buch",
  //     "Dativ: seinem neuen Buch",
  //   ],
  //   hint_correct: 2,
  //   hint_used: false,
  // },
  // {
  //   text: "Noch ein &dunkle Bier, bitte!",
  //   dunkle: ["dunklen", "dunkler", "dunkles"],
  //   dunkle_key: "dunkles",
  //   img: "bier.svg",
  //   answered: 0, // 0 - not asked, 1 - correct, 2 - failed
  //   visited: false,
  //   hint: [
  //     "Das Bier (Neuter)",
  //     "Nominativ: ein dunkles Bier",
  //     "Akkusativ: ein dunkles Bier",
  //     "Dativ: einem dunklen Bier",
  //   ],
  //   hint_correct: 2,
  //   hint_used: false,
  // },
];

export default questionsArray;
