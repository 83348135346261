import { useContext, useState } from "react";
import styled from "styled-components";

// components, context
import { FirebaseContext } from "../context/firebase.context";
import Login from "./login";

// images
import UserGray from "../img/user-gray.png";
import UserGreen from "../img/user-green.png";
import Exit from "../img/exit.png";

const User = () => {
  const { loggedUser, signOutUser } = useContext(FirebaseContext);
  const [hoverState, setHoverState] = useState(false);
  const [logOverlay, setLogOverlay] = useState(false);

  // image states
  const loggedUserIcon = () => {
    if (!hoverState) {
      return <img src={UserGreen} style={{ height: "100%" }} />;
    }
    if (hoverState) {
      return <img src={Exit} style={{ height: "100%" }} />;
    }
  };

  return (
    <UserContainer
      onClick={loggedUser ? signOutUser : () => setLogOverlay(true)}
      onMouseOver={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      {loggedUser && hoverState && (
        <LoggedUserElement>
          `Logged in as: {loggedUser.email}`
        </LoggedUserElement>
      )}
      {loggedUser ? (
        loggedUserIcon()
      ) : (
        <img src={UserGray} style={{ height: "100%" }} />
      )}
      {logOverlay && (
        <Login setLogOverlay={setLogOverlay} setHoverState={setHoverState} />
      )}
    </UserContainer>
  );
};

export default User;

// styles
const UserContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  @media (max-width: 600px) {
    cursor: pointer;
    height: 28px;
    width: 28px;
  }
`;

const LoggedUserElement = styled.div`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  background-color: #f8f7f6;
  color: rgba(0, 0, 0, 0.625);
`;
