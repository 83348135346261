import { useContext, useEffect } from "react";
import styled from "styled-components";

// context
import { QuestionsContext } from "../context/questions.context";
import { StatusBarContext } from "../context/status-bar.context";

// components
import Button from "./button";
import Hint from "./hint";

const MobileNav = () => {
  const {
    activeQuestionsArray,
    currentQuestionNumber,
    setCurrentQuestionNumber,
  } = useContext(QuestionsContext);

  const { hintMobileOpen, setHintMobileOpen } = useContext(StatusBarContext);

  const rightButtonStatus = () => {
    const currentQuestion = activeQuestionsArray[currentQuestionNumber];
    const nextQuestion = activeQuestionsArray[currentQuestionNumber + 1];
    if (nextQuestion) {
      if (!currentQuestion.answered) {
        return "inactive";
      }
      if (currentQuestion.answered && !nextQuestion.visited) {
        return "active";
      }
      if (currentQuestion.answered && nextQuestion.visited) {
        return "visited";
      }
    } else {
      return "inactive";
    }
  };

  const leftButtonStatus = () => {
    const currentQuestion = activeQuestionsArray[currentQuestionNumber];
    const nextQuestion = activeQuestionsArray[currentQuestionNumber - 1];
    if (nextQuestion) {
      return "visited";
    }
  };

  const rightClickHandler = () => {
    setCurrentQuestionNumber(currentQuestionNumber + 1);
  };

  const leftClickHandler = () => {
    setCurrentQuestionNumber(currentQuestionNumber - 1);
  };
  const rightButtonComponent = () => {
    const status = rightButtonStatus();
    if (status === "inactive") {
      return (
        <Button
          text="NEXT"
          color="white"
          textColor="rgba(0, 0, 0, 0.15)"
          mobile={true}
        />
      );
    }

    if (status === "active") {
      return (
        <Button
          text="NEXT"
          color="#3ED04C"
          textColor="black"
          mobile={true}
          onClickHandler={rightClickHandler}
        />
      );
    }

    if (status === "visited") {
      return (
        <Button
          text="NEXT"
          color="white"
          textColor="black"
          mobile={true}
          onClickHandler={rightClickHandler}
        />
      );
    }
  };

  const leftButtonComponent = () => {
    const status = leftButtonStatus();
    if (!status) {
      return (
        <Button
          text="PREVIOUS"
          color="white"
          textColor="rgba(0, 0, 0, 0.15)"
          mobile={true}
        />
      );
    }
    return (
      <Button
        text="PREVIOUS"
        color="white"
        textColor="black"
        mobile={true}
        onClickHandler={leftClickHandler}
      />
    );
  };

  const onClickHandler = () => {
    setHintMobileOpen(true);
  };

  return (
    <MobileNavContainer>
      {leftButtonComponent()}
      <HintMobile>
        {/* <QuestionMark /> */}
        <Hint isOnMobile={true} onClickHandler={onClickHandler} />
      </HintMobile>
      {rightButtonComponent()}
    </MobileNavContainer>
  );
};

export default MobileNav;

// styles

const MobileNavContainer = styled.div`
  display: none;
  gap: 1rem;
  @media (max-width: 600px) {
    display: flex;
    width: 90%;
    margin: auto;
    padding-top: 1.4rem;
  }
`;

const HintMobile = styled.div`
  width: 66px;
  transform: translateY(-0.4rem);
`;
