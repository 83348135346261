import styled from "styled-components";

// imgs
import { ReactComponent as HeartMobile } from "../img/heart-mobile.svg";
import { ReactComponent as HeartMobileGray } from "../img/heart-mobile-gray.svg";

const LivesMobile = ({ livesLeft }) => {
  return (
    <HeartMobileContainer>
      {Array.from(Array(livesLeft)).map((x, index) => (
        <HeartMobileStyled key={index} />
      ))}
      {Array.from(Array(3 - livesLeft)).map((x, index) => (
        <HeartEmptyStyled key={index} />
      ))}
    </HeartMobileContainer>
  );
};

export default LivesMobile;

// styles

const HeartMobileContainer = styled.div`
  margin-left: 0rem;
  padding-top: 0.3rem;
`;
const HeartMobileStyled = styled(HeartMobile)`
  transform: translateY(0.4rem);
`;
const HeartEmptyStyled = styled(HeartMobileGray)`
  transform: translateY(0.4rem);
`;
