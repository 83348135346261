import { useState, useContext, useEffect } from "react";
import styled from "styled-components";

// components
import HintOpen from "./hint-open";
import RoseBox from "./rose-box";

// context
import { QuestionsContext } from "../context/questions.context";
import { StatusBarContext } from "../context/status-bar.context";

// images
import { ReactComponent as QuestionMark } from "../img/question-mark.svg";
import { ReactComponent as QuestionMarkGray } from "../img/question-mark-gray.svg";
import { ReactComponent as QuestionMarkInactive } from "../img/question-mark-inactive.svg";

const Hint = ({ isOnMobile, onClickHandler }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [noMoreHints, setNoMoreHints] = useState(false);
  const {
    currentQuestionNumber,
    activeQuestionsArray,
    setActiveQuestionsArray,
    hintsCount,
    setHintsCount,
  } = useContext(QuestionsContext);
  const { hintsLeft, setHintsLeft, hintMobileOpen, setHintMobileOpen } =
    useContext(StatusBarContext);

  useEffect(() => {
    setIsOpen(false);
    setNoMoreHints(false);
  }, [currentQuestionNumber]);

  const onClick = () => {
    // if (isOnMobile) {
    //   console.log("mobile click!");
    //   return;
    // }

    if (hintMobileOpen) {
    }

    if (
      !activeQuestionsArray[currentQuestionNumber].answered &&
      !activeQuestionsArray[currentQuestionNumber].hint_used &&
      hintsLeft > 0 &&
      !isOpen &&
      hintsLeft === 1000
    ) {
      setHintsCount(hintsCount + 1);
    }

    if (
      !activeQuestionsArray[currentQuestionNumber].answered &&
      !activeQuestionsArray[currentQuestionNumber].hint_used &&
      hintsLeft > 0 &&
      hintsLeft !== 1000
    ) {
      setHintsLeft(hintsLeft - 1);
      setHintsCount(hintsCount + 1);
      const tempArray = [...activeQuestionsArray];
      tempArray[currentQuestionNumber].hint_used = true;
      setActiveQuestionsArray(tempArray);
    }
    if (
      !isOpen &&
      hintsLeft < 1 &&
      !activeQuestionsArray[currentQuestionNumber].answered
    ) {
      setNoMoreHints(true);
    }
    if (!isOpen && hintsLeft > 0) {
      isOnMobile ? setHintMobileOpen(true) : setIsOpen(!isOpen);
    }
    if (
      isOpen ||
      (!isOpen &&
        hintsLeft < 1 &&
        activeQuestionsArray[currentQuestionNumber].answered)
    ) {
      isOnMobile ? setHintMobileOpen(!hintMobileOpen) : setIsOpen(!isOpen);
      // setIsOpen(!isOpen);
    }
  };

  const isAnswered = () => {
    if (activeQuestionsArray[currentQuestionNumber].answered)
      return <QuestionMarkGray />;
    else if (hintsLeft < 1) {
      return <QuestionMarkInactive />;
    } else {
      return <QuestionMark />;
    }
  };

  const noMoreHintsHandler = () => {
    setTimeout(() => {
      setNoMoreHints(false);
    }, 3000);

    return <RoseBox text="No more hints left!" />;
  };

  return (
    <div onClick={onClick}>
      {isOnMobile && (
        <HintContainerMobile>
          {noMoreHints && !isOnMobile ? noMoreHintsHandler() : ""}
          {!isOpen || isOnMobile ? (
            isAnswered()
          ) : (
            <HintOpen isOnMobile={isOnMobile} />
          )}
        </HintContainerMobile>
      )}
      {!isOnMobile && (
        <HintContainer>
          {noMoreHints && !isOnMobile ? noMoreHintsHandler() : ""}
          {!isOpen || isOnMobile ? (
            isAnswered()
          ) : (
            <HintOpen isOnMobile={isOnMobile} />
          )}
        </HintContainer>
      )}
    </div>
  );
};

export default Hint;

// styles

const HintContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
  align-items: flex-end;
  @media (max-width: 600px) {
    display: none;
  }
`;

const HintContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -1rem;
  right: -1.6rem;
  cursor: pointer;
  align-items: flex-end;
`;
