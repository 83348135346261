import { useContext } from "react";
import "./App.css";

// components
import CardApp from "./components/card-app";
import Logo from "./components/logo.component";
import Hint from "./components/hint";
import Frontpage from "./components/frontpage";
import User from "./components/user";
import Footer from "./components/footer";

// context
import { StatusBarContext } from "./context/status-bar.context";

const App = () => {
  const { gameIsOn } = useContext(StatusBarContext);

  return (
    <div className="App">
      <div className="logo-container">
        <Logo />
        <User />
      </div>
      {!gameIsOn ? <Frontpage /> : null}
      {gameIsOn ? <CardApp /> : null}
      {gameIsOn ? <Hint /> : null}

      <Footer />
    </div>
  );
};

export default App;
