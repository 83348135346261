import { useContext } from "react";
import styled from "styled-components";

// context
import { StatusBarContext } from "../context/status-bar.context";

// images
import { ReactComponent as LogoSvg } from "../img/logo.svg";
import { ReactComponent as LogoWhiteSvg } from "../img/logo-white.svg";

const Logo = () => {
  const { setGameIsOn } = useContext(StatusBarContext);

  const backToFrontpage = () => {
    setGameIsOn(false);
  };

  return (
    <>
      <LogoDesktop className="logo" onClick={backToFrontpage} />
      <LogoMobile className="logo-mobile" onClick={backToFrontpage} />
    </>
  );
};

export default Logo;

// styles

const LogoDesktop = styled(LogoSvg)`
  cursor: pointer;
  @media (max-width: 600px) {
    display: none;
  }
`;

const LogoMobile = styled(LogoWhiteSvg)`
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`;
