import { useContext } from "react";
import styled from "styled-components";

// components
import Lives from "./lives";
import LivesMobile from "./lives-mobile";
import Timer from "./timer";

// context
import { StatusBarContext } from "../context/status-bar.context";
import { QuestionsContext } from "../context/questions.context";

// images
import { ReactComponent as CardsMobile } from "../img/cards-mobile.svg";
import { ReactComponent as HintsMobile } from "../img/hints-mobile.svg";

const StatusBar = () => {
  const { languageLevel, gameLevel, hintsLeft, livesLeft } =
    useContext(StatusBarContext);
  const { questionsLeft } = useContext(QuestionsContext);

  return (
    <>
      {/* DESKTOP */}
      <StatusBarContainer>
        <LanguageLevel>
          LEVEL: <b>{languageLevel}</b>
        </LanguageLevel>
        <QuestionsLeft>
          QUESTIONS: <b>{questionsLeft}</b>
        </QuestionsLeft>
        <HintsLeft>
          HINTS: <b>{hintsLeft === 1000 ? "∞" : hintsLeft}</b>
        </HintsLeft>
        <LivesLeft>
          LIVES: <Lives livesLeft={livesLeft} />
        </LivesLeft>
        <Timer />
      </StatusBarContainer>

      {/* MOBILE */}
      <StatuBarContainerMobile>
        <div className="language-level-mobile">{languageLevel}</div>
        <div>
          <LivesMobile livesLeft={livesLeft} />
        </div>
        <StasutBarCardsMobileContainer>
          <div>
            <StatusBarCardsMobile />
          </div>
          <div>{questionsLeft}</div>
        </StasutBarCardsMobileContainer>
        <StasutBarHintsMobileContainer>
          <div>
            <StatusBarHintsMobile />
          </div>
          <div>{hintsLeft === 1000 ? "∞" : hintsLeft}</div>
        </StasutBarHintsMobileContainer>
        <Timer />
      </StatuBarContainerMobile>
    </>
  );
};

export default StatusBar;

// styles

const StatusBarContainer = styled.div`
  width: 90rem;
  display: flex;
  justify-content: space-between;
  margin: 1rem auto 0.8rem auto;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.4);
  user-select: none;

  @media (max-width: 600px) {
    width: 90%;
    display: none;
  }
`;

const LanguageLevel = styled.div`
  background-color: #f8f7f6;
  padding: 1rem 1rem 1rem 1rem;
  @media (max-width: 600px) {
    font-weight: 700;
    padding-top: 0.8rem;
    padding-bottom: 0.2rem;
    padding-left: 1rem;
  }
`;

const QuestionsLeft = styled.div`
  background-color: #f8f7f6;
  padding: 1rem 1rem 1rem 1rem;
`;

const HintsLeft = styled.div`
  background-color: #f8f7f6;
  padding: 1rem 1rem 1rem 1rem;
`;

const LivesLeft = styled.div`
  background-color: #f8f7f6;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
`;

// mobiles status bar styles

const StatuBarContainerMobile = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: flex;
    width: 90%;
    font-size: 1.6rem;
    justify-content: space-between;
    margin: 0.8rem auto 0.8rem auto;
    background-color: #f8f7f6;
    font-weight: 700;
    align-items: center;
  }
`;

const StasutBarCardsMobileContainer = styled.div`
  @media (max-width: 600px) {
    display: flex;
    align-items: center;
  }
`;

const StasutBarHintsMobileContainer = styled.div`
  @media (max-width: 600px) {
    display: flex;
    align-items: center;
  }
`;

const StatusBarCardsMobile = styled(CardsMobile)`
  @media (max-width: 600px) {
    height: 2.4rem;
    padding-top: 0.4rem;
    padding-right: 0.6rem;
  }
`;

const StatusBarHintsMobile = styled(HintsMobile)`
  @media (max-width: 600px) {
    padding-top: 0.4rem;
    padding-right: 0.6rem;
  }
`;
