import styled from "styled-components";

const Button = ({
  text,
  color,
  onClickHandler,
  textColor = "white",
  mobile = false,
}) => {
  return (
    <>
      {mobile ? (
        <ButtonMobile
          onClick={onClickHandler}
          style={{ backgroundColor: color, color: textColor }}
        >
          {text}
        </ButtonMobile>
      ) : (
        <ButtonDesktop
          onClick={onClickHandler}
          style={{ backgroundColor: color, color: textColor }}
        >
          {text}
        </ButtonDesktop>
      )}
    </>
  );
};

export default Button;

// styles

const ButtonDesktop = styled.div`
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 60px;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #f8f7f6;
    cursor: pointer;
  }
`;

const ButtonMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 60px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #f8f7f6;
  cursor: pointer;
`;
