import { createContext, useState } from "react";

export const StatusBarContext = createContext({
  questionsLeft: null,
  setQuestionsLeft: () => null,
  languageLevel: null,
  setLanguageLevel: () => null,
  gameLevel: null,
  setGameLevel: () => null,
  hintsLeft: null,
  setHintsLeft: () => null,
  timeLeft: null,
  setTimeLeft: () => null,
  livesLeft: null,
  setLivesLeft: () => null,
  gameIsOn: null,
  setGameIsOn: () => null,
  gameIsFinished: null,
  setGameIsFinished: () => null,
  gameFinishedText: null,
  setGameFinishedText: () => null,
  hintMobileOpen: null,
  setHintMobileOpen: () => null,
});

export const StatusBarProvider = ({ children }) => {
  const [questionsLeft, setQuestionsLeft] = useState(20);
  const [languageLevel, setLanguageLevel] = useState("A2");
  const [gameLevel, setGameLevel] = useState(1);
  const [hintsLeft, setHintsLeft] = useState(3);
  const [timeLeft, setTimeLeft] = useState(180);
  const [livesLeft, setLivesLeft] = useState(3);
  const [gameIsOn, setGameIsOn] = useState(false);
  const [gameIsFinished, setGameIsFinished] = useState(false);
  const [gameFinishedText, setGameFinishedText] = useState("");
  const [hintMobileOpen, setHintMobileOpen] = useState(false);
  const value = {
    questionsLeft,
    setQuestionsLeft,
    languageLevel,
    setLanguageLevel,
    gameLevel,
    setGameLevel,
    hintsLeft,
    setHintsLeft,
    timeLeft,
    setTimeLeft,
    livesLeft,
    setLivesLeft,
    gameIsOn,
    setGameIsOn,
    gameIsFinished,
    setGameIsFinished,
    gameFinishedText,
    setGameFinishedText,
    hintMobileOpen,
    setHintMobileOpen,
  };
  return (
    <StatusBarContext.Provider value={value}>
      {children}
    </StatusBarContext.Provider>
  );
};
