import styled from "styled-components";

// imgs
import { ReactComponent as Heart } from "../img/heart.svg";
import { ReactComponent as HeartEmpty } from "../img/heart-empty.svg";

const Lives = ({ livesLeft }) => {
  return (
    <LiveContainer>
      {Array.from(Array(livesLeft)).map((x, index) => (
        <HeartStyled key={index} />
      ))}
      {Array.from(Array(3 - livesLeft)).map((x, index) => (
        <HeartEmptyStyled key={index} />
      ))}
    </LiveContainer>
  );
};

export default Lives;

// styles

const LiveContainer = styled.div`
  margin-left: 1rem;
`;
const HeartStyled = styled(Heart)`
  transform: translateY(0.4rem);
`;

const HeartEmptyStyled = styled(HeartEmpty)`
  transform: translateY(0.4rem);
`;
