import { useContext, useEffect } from "react";
import styled from "styled-components";

// components
import StatusBar from "./status-bar";
import QuestionCard from "./question-card";
import NavArrow from "./nav-arrow";
import GameOver from "./game-over";
import MobileNav from "./mobile-nav";
import HintOpen from "./hint-open";

// contexts
import { QuestionsContext } from "../context/questions.context";
import { StatusBarContext } from "../context/status-bar.context";

const CardApp = () => {
  const {
    activeQuestionsArray,
    currentQuestionNumber,
    setCurrentQuestionNumber,
    questionsLeft,
  } = useContext(QuestionsContext);

  const {
    setGameIsFinished,
    setGameFinishedText,
    timeLeft,
    livesLeft,
    hintMobileOpen,
  } = useContext(StatusBarContext);

  // game over
  useEffect(() => {
    if (questionsLeft === 0) {
      setTimeout(() => {
        setGameIsFinished(true);
        setGameFinishedText("Finished! Well done");
      }, 1000);
    }
    if (timeLeft === 0) {
      setTimeout(() => {
        setGameIsFinished(true);
        setGameFinishedText("You've run out of time");
      }, 1000);
    }
    if (livesLeft === 0) {
      setTimeout(() => {
        setGameIsFinished(true);
        setGameFinishedText("You've run out of lives!");
      }, 1000);
    }
  }, [questionsLeft, timeLeft, livesLeft]);

  const rightButtonStatus = () => {
    const currentQuestion = activeQuestionsArray[currentQuestionNumber];
    const nextQuestion = activeQuestionsArray[currentQuestionNumber + 1];
    if (nextQuestion) {
      if (!currentQuestion.answered) {
        return "inactive";
      }
      if (currentQuestion.answered && !nextQuestion.visited) {
        return "active";
      }
      if (currentQuestion.answered && nextQuestion.visited) {
        return "visited";
      }
    }
  };

  const leftButtonStatus = () => {
    const currentQuestion = activeQuestionsArray[currentQuestionNumber];
    const nextQuestion = activeQuestionsArray[currentQuestionNumber - 1];
    if (nextQuestion) {
      return "visited";
    }
  };

  const rightClickHandler = () => {
    setCurrentQuestionNumber(currentQuestionNumber + 1);
  };

  const leftClickHandler = () => {
    setCurrentQuestionNumber(currentQuestionNumber - 1);
  };

  return (
    <CardAppContainer>
      <GameOver />
      <LeftCardAppContainer>
        <NavArrow
          direction="left"
          status={leftButtonStatus()}
          clickHandler={leftClickHandler}
        />
      </LeftCardAppContainer>
      <MiddleCardAppContainer>
        <StatusBar />
        <QuestionCard />
        <MobileNav />
        <HintMobileContainer>
          {hintMobileOpen ? <HintOpen /> : ""}
        </HintMobileContainer>
      </MiddleCardAppContainer>
      <RightCardAppContainer>
        <NavArrow
          direction="right"
          status={rightButtonStatus()}
          clickHandler={rightClickHandler}
        />
      </RightCardAppContainer>
    </CardAppContainer>
  );
};

export default CardApp;

// styles

const CardAppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.8rem;
  max-height: 66rem;
  @media (max-width: 600px) {
    gap: 0;
    flex-basis: auto;
  }
`;

const HintMobileContainer = styled.div`
  position: absolute;
  width: 100%;
  margin: auto;
  bottom: 0rem;
  transform: translateY(-50%);
`;

const MiddleCardAppContainer = styled.div`
  @media (max-width: 600px) {
    width: 100%;
    gap: 0;
    display: flex;
    flex-direction: column;
  }
`;

const LeftCardAppContainer = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

const RightCardAppContainer = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;
