import { useContext } from "react";
import styled from "styled-components";

// contexts
import { StatusBarContext } from "../context/status-bar.context";
import { QuestionsContext } from "../context/questions.context";
import { FirebaseContext } from "../context/firebase.context";

const ChooseModeCard = ({
  level,
  name,
  cardscount,
  duration,
  hints,
  ultimate,
  type,
}) => {
  const { loggedUser, logGoogleUser, mistakes } = useContext(FirebaseContext);

  const {
    setQuestionsLeft,
    setGameLevel,
    setHintsLeft,
    setTimeLeft,
    setLivesLeft,
    setGameIsOn,
    setGameIsFinished,
    questionsLeft,
  } = useContext(StatusBarContext);

  const {
    prepareQuestionsArray,
    setQuestionsCount,
    setInitialTime,
    setCurrentQuestionNumber,
    setMistakesCount,
    setHintsCount,
  } = useContext(QuestionsContext);

  const startGame = () => {
    if (type === "review" && !mistakes.length) {
      logGoogleUser();
      return;
    }
    setGameIsFinished(false);
    setCurrentQuestionNumber(0);
    setQuestionsLeft(cardscount);
    setQuestionsCount(0);
    if (type === "review") {
      prepareQuestionsArray(cardscount, true);
    } else {
      prepareQuestionsArray(cardscount, false);
    }
    setHintsLeft(hints);
    setTimeLeft(duration * 60);
    setInitialTime(duration * 60);
    setLivesLeft(3);
    setGameIsOn(true);
    setMistakesCount(0);
    setHintsCount(0);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <ChooseModeCardContainer onClick={startGame}>
      <ChooseModeLevel>{level}</ChooseModeLevel>
      <ChooseModeName>
        <span>{name}</span>
      </ChooseModeName>
      <ChooseModeDetails>
        {type === "review" ? (
          <div>Your previous</div>
        ) : (
          <div>{cardscount} cards</div>
        )}
        {type === "review" ? <div>mistakes</div> : <div>{duration} min</div>}
        {type === "review" ? (
          <div>
            {loggedUser ? (
              <span>{cardscount} cards</span>
            ) : (
              <PleaseLogin>Please log in to enable</PleaseLogin>
            )}
          </div>
        ) : (
          <div>{hints === 1000 ? "Unlimited" : hints} hints</div>
        )}
      </ChooseModeDetails>
    </ChooseModeCardContainer>
  );
};

export default ChooseModeCard;

// styles

const ChooseModeCardContainer = styled.div`
  background: #f8f7f6;
  border: 1px solid #000000;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  width: 26.5rem;
  min-width: 26.5rem;
  height: 25.9rem;

  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  @media (max-width: 600px) {
    flex-grow: 1;
    height: 22rem;
  }
`;

const ChooseModeCardContainerUltimate = styled.div`
  background: #e4f709;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.4);
  width: 26.5rem;
  height: 25.9rem;
  border-radius: 30px;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  min-width: 26.5rem;
  cursor: pointer;
`;

const ChooseModeLevel = styled.div`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  color: #000000;
  display: flex;
  margin: 1.6rem 0 0 2rem;
`;

const ChooseModeName = styled.div`
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  color: #3ed04c;
  margin-top: 6rem;
  margin-left: 2rem;
  text-align: left;
  @media (max-width: 600px) {
    margin-top: 3rem;
    margin-bottom: 0rem;
  }
`;

const ChooseModeDetails = styled.div`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 2rem;
  margin-top: 1.6rem;
  @media (max-width: 600px) {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-top: 0.8rem;
  }
`;

const PleaseLogin = styled.span`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #3ed04c;
`;

// old styles

// .choose-mode-card-container-ultimate {
//   background: #e4f709;
//   box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.4);
//   width: 26.5rem;
//   height: 25.9rem;
//   border-radius: 30px;
//   font-family: "Roboto Mono";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 20px;
//   line-height: 30px;
//   min-width: 26.5rem;
//   /* identical to box height, or 150% */

//   cursor: pointer;
// }

// .choose-mode-card-container-ultimate .choose-mode-name {
//   margin-top: 2.8rem;
//   margin-left: 2rem;
//   font-family: "Roboto Mono";
//   font-style: normal;
//   font-weight: 700;
//   font-size: 30px;
//   line-height: 33px;
//   /* or 110% */

//   color: #ff57bc;
// }

// .choose-mode-card-container-ultimate .choose-mode-details {
//   color: rgba(0, 0, 0, 0.6);
// }
