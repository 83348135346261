import styled from "styled-components";

// components
import ChooseModeCard from "./choose-mode-card";

// content
import questionsArray from "../content/adj-endings/adj-endings";

// imgaes
import { ReactComponent as DoneImg } from "../img/done-black.svg";

const Frontpage = () => {
  return (
    <FrontPageContainer>
      <Tagline>
        Learning German? Grammar is a beast.
        <br />
        <TaglineBold>Master it playfully</TaglineBold>
      </Tagline>
      <SectionName>Adjective Endings</SectionName>
      <ExampleContainer>
        Noch ein <ExampleWrong>dunklen</ExampleWrong> Bier! Noch ein{" "}
        <ExampleCorrect>dunkles</ExampleCorrect>
        <span>
          <DoneImgStyled />
        </span>{" "}
        Bier!
      </ExampleContainer>
      <ChooseModeContainer>
        {/* <ChooseModeCard
          level="A2"
          name="REVIEW"
          cardscount={mistakes.length}
          duration={10}
          hints={1000}
          type="review"
        /> */}
        <ChooseModeCard
          level="A2"
          name="Study"
          cardscount={30}
          duration={5}
          hints={1000}
        />
        {/* <ChooseModeCard
          level="A2"
          name="Play"
          cardscount={3}
          duration={5}
          hints={3}
        /> */}
        <ChooseModeCard
          level="A2"
          name="Play"
          cardscount={questionsArray.length}
          duration={10}
          hints={3}
          ultimate={true}
        />
      </ChooseModeContainer>
      {/* <Scoreboard /> */}
    </FrontPageContainer>
  );
};

export default Frontpage;

// styles

const FrontPageContainer = styled.div`
  max-width: 113.5rem;
  margin: 0 auto;
`;

const Tagline = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 55px;
  line-height: 61px;
  text-align: center;
  color: #000000;
  background-color: #f8f7f6;
  padding: 1rem 1rem 1rem 1rem;
  margin-top: 4.5rem;
  margin-bottom: 10rem;
  @media (max-width: 600px) {
    font-size: 3.8rem;
    line-height: 4.6rem;
    margin-bottom: 4rem;
  }
`;

const TaglineBold = styled.span`
  font-weight: 600;
`;

const SectionName = styled.div`
  font-family: "Roboto Mono";
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 46px;
  color: #ff57bc;
  background-color: #f8f7f6;
  padding: 0rem 1rem 1rem 1.6rem;
  width: 34.8rem;
  margin-bottom: 1rem;
  @media (max-width: 600px) {
    font-size: 2.8rem;
    padding: 0rem 1rem 1rem 2.8rem;
    align-self: left;
    width: auto;
    text-align: left;
    margin-bottom: 0rem;
  }
`;

const ExampleContainer = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 46px;
  color: #000000;
  background-color: #f8f7f6;
  padding: 0rem 1rem 1rem 0rem;
  width: 68.5rem;
  margin-bottom: 2.5rem;
  @media (max-width: 600px) {
    width: auto;
    text-align: left;
    font-size: 2.4rem;
    line-height: 3rem;
    padding: 0rem 1rem 1rem 2.8rem;
  }
`;

const ExampleWrong = styled.span`
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  color: #ff57bc;
`;

const ExampleCorrect = styled.span`
  color: #3ed04c;
  font-weight: 400;
`;

const DoneImgStyled = styled(DoneImg)`
  width: 2.3rem;
  height: 2.3rem;
  margin-left: 0.4rem;
  transform: translateY(0.2rem);
`;

const ChooseModeContainer = styled.div`
  display: flex;
  gap: 2rem;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    padding: 0rem 2.8rem 1rem 2.8rem;
  }
`;
