import { useState, useEffect, useContext } from "react";
import styled from "styled-components";

// context
import { QuestionsContext } from "../context/questions.context";

const ButtonMultipleChoice = ({
  word,
  options,
  key_answer,
  correctChoiceHandler,
  wrongChoiceHandler,
  alreadyAnswered,
}) => {
  const { currentQuestionNumber } = useContext(QuestionsContext);
  const [hoverOn, setHoverOn] = useState(false);
  const [wordChoice, setWordChoice] = useState(word);
  const [choiceIsMade, setChoiceIsMade] = useState(false);
  const [elementWidth, setElementWidth] = useState(100);
  const [answeredCorrect, setAnsweredCorret] = useState(false);
  const mouseEnterHandler = () => {
    if (!choiceIsMade) setHoverOn(true);
  };

  useEffect(() => {
    if (alreadyAnswered) {
      setAnsweredCorret(true);
    }
  }, [alreadyAnswered]);

  useEffect(() => {
    let maxChoiceWidth = 0;
    const widthCalculationObj = document.querySelector(".width-calculation");
    options.forEach((element) => {
      widthCalculationObj.textContent = element;
      const currentWidth = widthCalculationObj.clientWidth;
      if (currentWidth > maxChoiceWidth) {
        maxChoiceWidth = currentWidth;
      }
    });
    maxChoiceWidth = maxChoiceWidth - 4;
    setElementWidth(maxChoiceWidth);
    if (alreadyAnswered) {
      setWordChoice(key_answer);
      setChoiceIsMade(true);
    }
    if (!alreadyAnswered) {
      setChoiceIsMade(false);
      setWordChoice(word);
    }
  }, [currentQuestionNumber, alreadyAnswered, key_answer, word, options]);

  const mouseLeaveHandler = () => {
    setHoverOn(false);
  };

  const clickHandler = (event) => {
    event.stopPropagation();
    const userChoice = event.target.textContent;
    setWordChoice(userChoice);
    setChoiceIsMade(true);
    if (userChoice === key_answer) {
      //   displayCorrect();
      setAnsweredCorret(true);
      correctChoiceHandler(word);
    } else {
      wrongChoiceHandler();
      setAnsweredCorret(false);
    }
    setHoverOn(false);
  };

  const reOpenHandler = () => {
    if (!answeredCorrect) {
      setHoverOn(true);
    }
  };

  return (
    <>
      <FakeInitialOption className="width-calculation">mmm</FakeInitialOption>
      <InitialOption
        hover={hoverOn}
        choiceIsMade={choiceIsMade}
        answeredCorrect={answeredCorrect}
        onMouseEnter={mouseEnterHandler}
        onClick={reOpenHandler}
        style={{ width: elementWidth }}
      >
        {/* <div
          className={
            (hoverOn
              ? "initial-option initial-option-hidden"
              : "initial-option") +
            (choiceIsMade ? " option-chosen" : "") +
            (choiceIsMade && answeredCorrect ? " option-correct" : "") +
            (choiceIsMade && !answeredCorrect ? " option-wrong" : "")
          }
          onMouseEnter={mouseEnterHandler}
          onClick={reOpenHandler}
          style={{ width: elementWidth }}
        > */}
        <OptionsContainer hover={hoverOn} onMouseLeave={mouseLeaveHandler}>
          {options.map((element) => {
            return (
              <OptionText key={element} onClick={clickHandler}>
                {element}
              </OptionText>
            );
          })}
          {/* </div> */}
        </OptionsContainer>
        {wordChoice}
        {!choiceIsMade ? "..." : ""}
        {/* </div> */}
      </InitialOption>
    </>
  );
};

export default ButtonMultipleChoice;

// styles

const InitialOption = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  background-color: ${(props) =>
    props.choiceIsMade && props.answeredCorrect ? "#3ed04c" : ""};
  border: 1px solid #6d6c6c;
  border: ${(props) =>
    props.choiceIsMade && props.answeredCorrect ? "1px solid #3ed04c;" : ""};
  color: ${(props) =>
    props.choiceIsMade && props.answeredCorrect ? "black" : "6d6c6c"};
  background-color: ${(props) =>
    props.choiceIsMade && !props.answeredCorrect ? "#ff57bc" : ""};
  border: ${(props) =>
    props.choiceIsMade && !props.answeredCorrect ? "1px solid #ff57bc;" : ""};
  box-sizing: border-box;
  border-radius: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-bottom: 0.4rem;
  cursor: pointer;
  position: relative;
  @media (max-width: 600px) {
    font-style: normal;
    font-weight: 400;
    font-size: 2.2rem;
    border: 1px solid #6d6c6c;
    box-sizing: border-box;
    border-radius: 2rem;
    padding-right: 1.8rem;
    padding-left: 1.4rem;
    padding-bottom: 0.2rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
    display: inline-block;
    border: ${(props) =>
      props.choiceIsMade && props.answeredCorrect ? "1px solid #3ed04c;" : ""};
    color: ${(props) =>
      props.choiceIsMade && props.answeredCorrect ? "black" : "6d6c6c"};
    background-color: ${(props) =>
      props.choiceIsMade && !props.answeredCorrect ? "#ff57bc" : ""};
    border: ${(props) =>
      props.choiceIsMade && !props.answeredCorrect ? "1px solid #ff57bc;" : ""};
  }
`;

const OptionsContainer = styled.div`
  display: ${(props) => (props.hover ? "inline-block" : "none")};
  position: absolute;
  // transform: translateY(-32%);
  // transform: translateX(-15%);
  transform: translate(-2.8rem, -32%);
  border: 1px solid #6d6c6c;
  box-sizing: border-box;
  border-radius: 2rem;
  cursor: pointer;
  text-align: left;
  background-color: #f8f7f6;
  @media (max-width: 600px) {
    position: absolute;
    transform: translate(-2.1rem, -37%);
    background-color: #2a262a;
  }
`;

const OptionText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 30px;
  color: #6d6c6c;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  :hover {
    color: #1400ff;
  }
  @media (max-width: 600px) {
    font-style: normal;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 30px;
    /* or 107% */

    color: white;
    padding-right: 1.8rem;
    padding-left: 1.4rem;

    margin-top: 2rem;
    margin-bottom: 2rem;
    :hover {
      color: #ffffff;
    }
  }
`;

const FakeInitialOption = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  color: #6d6c6c;
  border: 1px solid #6d6c6c;
  box-sizing: border-box;
  border-radius: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-bottom: 0.4rem;
  display: inline-block;
  position: absolute;
  opacity: 0;
  @media (max-width: 600px) {
    font-style: normal;
    font-weight: 400;
    font-size: 2.2rem;
    color: #6d6c6c;
    border: 1px solid #6d6c6c;
    box-sizing: border-box;
    border-radius: 2rem;
    padding-right: 1.8rem;
    padding-left: 1.4rem;
    padding-bottom: 0rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
    display: inline-block;
  }
`;
