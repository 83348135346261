// https://blog.greenroots.info/how-to-create-a-countdown-timer-using-react-hooks#heading-the-custom-hook-to-perform-the-countdown

import { useContext, useEffect } from "react";
import styled from "styled-components";

// context
import { StatusBarContext } from "../context/status-bar.context";

const oneSecTimeout = (timeLeft, setTimeLeft) => {
  if (timeLeft > 0) {
    setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
  }
};

const Timer = () => {
  const { timeLeft, setTimeLeft, gameIsFinished } =
    useContext(StatusBarContext);

  useEffect(() => {
    if (!gameIsFinished) {
      oneSecTimeout(timeLeft, setTimeLeft);
    }
  }, [timeLeft, gameIsFinished, setTimeLeft]);

  let minutes = Math.floor(timeLeft / 60);
  let seconds = timeLeft - minutes * 60;
  if (seconds >= 0 && seconds < 10) {
    seconds = `0${seconds}`;
  } else seconds = "" + seconds;
  return <TimerContainer>{`${minutes}:${seconds}`}</TimerContainer>;
};

export default Timer;

// styles

const TimerContainer = styled.div`
  font-family: "VT323", monospace;
  background-color: #f8f7f6;
  padding: 1rem 2rem 1rem 2rem;
  font-style: normal;
  font-weight: 400;
  font-size: 37px;
  color: #1400ff;
  @media (max-width: 600px) {
    font-size: 2.8rem;
    padding: 0.3rem 1rem 0rem 1rem;
  }
`;
