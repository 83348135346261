import { useContext } from "react";
import styled from "styled-components";

// context
import { QuestionsContext } from "../context/questions.context";
import { StatusBarContext } from "../context/status-bar.context";

const HintOpen = () => {
  const { activeQuestionsArray, currentQuestionNumber } =
    useContext(QuestionsContext);
  const { hintMobileOpen, setHintMobileOpen } = useContext(StatusBarContext);

  const mobileClickHandler = () => {
    setHintMobileOpen(false);
  };

  return (
    <>
      <HintOpenContainer
        onClick={hintMobileOpen ? mobileClickHandler : () => null}
      >
        <HintFirstSentence>
          {activeQuestionsArray[currentQuestionNumber].hint[0]}
        </HintFirstSentence>
        {activeQuestionsArray[currentQuestionNumber].hint.map(
          (hintLine, index) => {
            if (index === 0) return;
            return (
              <div key={index}>
                {index ===
                activeQuestionsArray[currentQuestionNumber].hint_correct ? (
                  <HintHighlighted>{hintLine}</HintHighlighted>
                ) : (
                  <HintLines>{hintLine}</HintLines>
                )}
              </div>
            );
          }
        )}
      </HintOpenContainer>
    </>
  );
};

export default HintOpen;

// styles

const HintOpenContainer = styled.div`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
  /* or 127% */

  color: #000000;
  background: #f8f7f6;
  border: 1px solid #ff57bc;
  box-sizing: border-box;
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 2.5rem;
  padding: 3.4rem 2.4rem 3.4rem 2.4rem;
  user-select: none;
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 600px) {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #000000;
    background: #f8f7f6;
    border: 1px solid #ff57bc;
    box-sizing: border-box;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.25);
    border-radius: 2.5rem;
    padding: 3.4rem 1rem 3.4rem 1rem;
    user-select: none;
    cursor: pointer;
    position: relative;
    margin: 0;
  }
`;

const HintFirstSentence = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: 600;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const HintLines = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.4rem;
`;

const HintHighlighted = styled.div`
  display: flex;
  justify-content: flex-start;
  background: linear-gradient(120deg, #fb9ad4 50%, #fb9ad4 50%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 65%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.4rem;
`;
