import styled from "styled-components";

// images
import { ReactComponent as ArrowRightInactive } from "../img/arrow-right-inactive.svg";
import { ReactComponent as ArrowLeftInactive } from "../img/arrow-left-inactive.svg";
import { ReactComponent as ArrowRightGreen } from "../img/arrow-right-green.svg";
import { ReactComponent as ArrowLeftGreen } from "../img/arrow-left-green.svg";
import { ReactComponent as ArrowLeftVisited } from "../img/arrow-left-visited.svg";
import { ReactComponent as ArrowRightVisited } from "../img/arrow-right-visited.svg";

const NavArrow = ({ direction, status, clickHandler }) => {
  return (
    <ArrowContainer>
      {(direction === "right") & (status === "inactive") ? (
        <ArrowRightInactive />
      ) : (
        ""
      )}
      {(direction === "right") & (status === "active") ? (
        <ArrowActive>
          <ArrowRightGreen onClick={clickHandler} />
        </ArrowActive>
      ) : (
        ""
      )}
      {(direction === "right") & (status === "visited") ? (
        <ArrowActive>
          <ArrowRightVisited onClick={clickHandler} />
        </ArrowActive>
      ) : (
        ""
      )}
      {(direction === "left") & (status === "inactive") ? (
        <ArrowLeftInactive />
      ) : (
        ""
      )}
      {(direction === "left") & (status === "active") ? (
        <ArrowActive>
          <ArrowLeftGreen onClick={clickHandler} />
        </ArrowActive>
      ) : (
        ""
      )}
      {(direction === "left") & (status === "visited") ? (
        <ArrowActive>
          <ArrowLeftVisited onClick={clickHandler} />
        </ArrowActive>
      ) : (
        ""
      )}
    </ArrowContainer>
  );
};

export default NavArrow;

// styles

const ArrowContainer = styled.div`
  padding-top: 6.5rem;
  width: 3.5rem;
`;

const ArrowActive = styled.div`
  cursor: pointer;
`;
