import styled from "styled-components";

const RoseBox = ({ text }) => {
  return (
    <RoseBoxContrainer className="rose-box-container">{text}</RoseBoxContrainer>
  );
};

export default RoseBox;

const RoseBoxContrainer = styled.div`
  .rose-box-container {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #000000;
    background: #f8f7f6;
    border: 1px solid #ff57bc;
    box-sizing: border-box;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.25);
    border-radius: 2.5rem;
    padding: 1.4rem 2.4rem 1.4rem 2.4rem;
    margin-bottom: 1.2rem;
    user-select: none;
    cursor: pointer;
  }
`;
