import { useState, useContext, useEffect } from "react";
import styled from "styled-components";

// components
import ButtonMultipleChoice from "./button-multiple-choice";

// helpers
import randomNumber from "../utils/randomNumber";

// context
import { QuestionsContext } from "../context/questions.context";
import { StatusBarContext } from "../context/status-bar.context";

// images
import { ReactComponent as DoneImg } from "../img/done-black.svg";

const QuestionCard = () => {
  const {
    activeQuestionsArray,
    currentQuestionNumber,
    setActiveQuestionsArray,
    questionsCount,
    setQuestionsCount,
    mistakesCount,
    setMistakesCount,
  } = useContext(QuestionsContext);
  const { livesLeft, setLivesLeft } = useContext(StatusBarContext);
  const question = activeQuestionsArray[currentQuestionNumber];
  const textArray = question.text.split(" ");

  const [choicesTracker, setChoicesTracker] = useState({ test: false });

  useEffect(() => {
    const initialChoicesObj = {};
    textArray.forEach((word) => {
      if (word.slice(0, 1) === "&") {
        const wordSliced = word.slice(1, word.length);
        initialChoicesObj[wordSliced] = false;
      }
    });
    setChoicesTracker(initialChoicesObj);
    const tempArray = [...activeQuestionsArray];
    tempArray[currentQuestionNumber].visited = true;
    setActiveQuestionsArray(tempArray);
  }, [currentQuestionNumber]);

  // function triggered by button-multiple-choice.component
  // when correct choice is made
  // marks corresponding property in choicesTracker obj as true
  const correctChoiceHandler = (word) => {
    setChoicesTracker({ ...choicesTracker, [word]: true });
  };

  // all choices are correct? if so, trigger the final stage
  useEffect(() => {
    const entries = Object.values(choicesTracker);
    if (!entries.includes(false)) {
      setQuestionsCount(questionsCount + 1);
      const tempArray = [...activeQuestionsArray];
      tempArray[currentQuestionNumber].answered = true;
      setActiveQuestionsArray(tempArray);
    }
  }, [choicesTracker]);

  const wrongChoiceHandler = () => {
    if (!activeQuestionsArray[currentQuestionNumber].answeredIncorrectly) {
      setLivesLeft(livesLeft - 1);
      setMistakesCount(mistakesCount + 1);
    }
    const tempArray = [...activeQuestionsArray];
    tempArray[currentQuestionNumber].answeredIncorrectly = true;
    setActiveQuestionsArray(tempArray);
  };

  return (
    <CardContainer>
      <div>
        {activeQuestionsArray[currentQuestionNumber].answered ? (
          <DoneImageStyled />
        ) : (
          ""
        )}
      </div>
      <PicContainer>
        <img src={`/img/${question.img}`} />
      </PicContainer>
      <QuestionText>
        {textArray.map((word) => {
          if (word[0] === "&") {
            const wordSliced = word.slice(1, word.length);
            const key_to_answer = `${wordSliced}_key`;
            return (
              <span key="multiple-choice">
                <ButtonMultipleChoice
                  word={wordSliced}
                  options={question[wordSliced]}
                  key_answer={question[key_to_answer]}
                  correctChoiceHandler={correctChoiceHandler}
                  wrongChoiceHandler={wrongChoiceHandler}
                  alreadyAnswered={question.answered}
                />{" "}
              </span>
            );
          } else {
            const keyNumber = randomNumber(0, 100000000).toString();
            return <span key={keyNumber}>{word} </span>;
          }
        })}
      </QuestionText>
    </CardContainer>
  );
};

export default QuestionCard;

// styles

const CardContainer = styled.div`
  width: 95.5rem;
  height: 58rem;
  margin: auto;
  background: #f8f7f6;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.45);
  border-radius: 30px;
  position: relative;
  @media (max-width: 600px) {
    width: 99%;
    height: 60vh;
  }
`;

const DoneImageStyled = styled(DoneImg)`
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
  @media (max-width: 600px) {
    height: 2.6rem;
    width: 2.6rem;
  }
`;

const PicContainer = styled.div`
  margin-top: 6.4rem;
  margin-bottom: 6rem;
  height: 30rem;
  user-select: none;
  @media (max-width: 600px) {
    margin-top: 1.8rem;
    margin-bottom: 0rem;
    height: 35%;
    user-select: none;
    width: 100%;
    img {
      height: 100%;
    }
  }
`;

const QuestionText = styled.div`
  user-select: none;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 4.3rem;
  /* or 139% */

  color: #000000;
  margin-right: 5rem;
  margin-left: 5rem;
  @media (max-width: 600px) {
    font-size: 2.2rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    margin-top: 2.4rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
    justify-content: center;
    line-height: 2.8rem;
  }
`;
