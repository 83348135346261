import { useState, useContext, useRef, useEffect } from "react";
import styled from "styled-components";
import validator from "validator";

// context
import { FirebaseContext } from "../context/firebase.context";

// images
import { ReactComponent as Checkbox } from "../img/checkbox.svg";
import { ReactComponent as CheckboxChecked } from "../img/checkbox-checked.svg";

// component
const Login = ({ setLogOverlay, setHoverState }) => {
  const [error, setErrorMessage] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [optIn, setOptIn] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const { signInUser, signUpUser } = useContext(FirebaseContext);
  const ref = useRef();

  // login handler
  const loginHandler = async (e) => {
    e.preventDefault();

    // check if input is correct
    if (!validator.isEmail(email)) {
      setErrorMessage("Please enter a valid email");
      return;
    }
    if (!password) {
      setErrorMessage("Please enter a password");
      return;
    }

    setIsPending(true);

    const res = await signInUser({ email, password });
    setIsPending(false);

    // log in success
    if (res.email) {
      setHoverState(false);
      setLogOverlay(false);
      return;
    }

    // log in - wrong password
    if (res.code === "auth/wrong-password") {
      setPassword("");
      setErrorMessage("Wrong password");
      return;
    }

    // log in - user doesn't exist
    if (res.code === "auth/user-not-found") {
      setShowCheckboxes(true);
      setErrorMessage("Please accept our privacy policy");
    }
  };

  // sign up

  const signupHandler = async (e) => {
    e.preventDefault();
    setIsPending(true);
    if (!privacy) {
      setErrorMessage("You must accept our privacy policy to proceed");
      setIsPending(false);
      return;
    }
    const settings = { optIn };
    const res = await signUpUser({ email, password, settings });
    setIsPending(false);

    // sign up - success
    if (res.email) {
      setHoverState(false);
      setLogOverlay(false);
      return;
    }
    if (res.code === "auth/weak-password") {
      setShowCheckboxes(false);
      setErrorMessage("Password should be six characters or longer");
      return;
    }
  };

  // close overlay when clicked outside of the component
  useEffect(() => {
    document.body.addEventListener(
      "click",
      (event) => {
        if (!ref.current) {
          return;
        }
        if (ref.current.contains(event.target)) {
          return;
        }
        setLogOverlay(false);
      },
      { capture: true }
    );
  }, []);

  return (
    <LoginOverlay>
      <LoginContainer ref={ref}>
        <LoginHeader>LOGIN OR REGISTER</LoginHeader>
        <LoginForm onSubmit={!showCheckboxes ? loginHandler : signupHandler}>
          {!showCheckboxes && (
            <>
              <label>
                <LoginInput
                  type="text"
                  placeholder="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </label>
              <label>
                <LoginInput
                  type="password"
                  placeholder="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </label>
            </>
          )}
          {showCheckboxes && (
            <CheckboxesContainer>
              <CheckboxContainer onClick={() => setPrivacy(!privacy)}>
                {privacy ? <CheckboxChecked /> : <Checkbox />}
                <PrivacyText>I accept privacy policy</PrivacyText>
              </CheckboxContainer>
              <CheckboxContainer onClick={() => setOptIn(!optIn)}>
                {optIn ? <LoginChecboxChecked /> : <LoginCheckbox />}
                <PrivacyText>
                  I want to recieve newsletter with grammar tips and reminders
                </PrivacyText>
              </CheckboxContainer>
            </CheckboxesContainer>
          )}
          <LoginButton>
            {isPending ? (
              <ButtonTextWhite>Loading...</ButtonTextWhite>
            ) : (
              <ButtonTextRed>Sign up / Sign in</ButtonTextRed>
            )}
          </LoginButton>
        </LoginForm>
        <LoginError>{error}</LoginError>
      </LoginContainer>
    </LoginOverlay>
  );
};

export default Login;

// STYLES

const LoginOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  cursor: auto;
`;

const LoginContainer = styled.div`
  width: 55.5rem;
  height: 60rem;
  margin: 10rem auto;
  background: #f8f7f6;
  border: 1px solid #ff57bc;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.45);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    width: 100vw;
    height: 50vh;
    margin: 25vh auto;
  }
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LoginHeader = styled.div`
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  color: #000000;
`;

const LoginInput = styled.input`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-color: #000000;
  color: #333333;
  width: 30rem;
  margin: 0 auto 2rem auto;
  border-width: 1px;
  :focus,
  :active {
    outline: none;
  }
  @media (max-width: 600px) {
    width: 85vw;
  }
`;

const LoginButton = styled.button`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-style: none;
  background-color: #3ed04c;
  width: 32.4rem;
  margin: 0rem auto 2rem auto;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 90vw;
  }
`;

const ButtonTextWhite = styled.span`
  color: #ffffff;
  :hover {
    color: #ffffff;
  }
`;

const ButtonTextRed = styled.span`
  color: #ffffff;
  :hover {
    color: #ff57bc;
  }
`;

const LoginError = styled.div`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ff57bc;
  height: 4rem;
  margin: 0 4rem 0 4rem;
  height: 2.4rem;
  flex-shrink: 0;
  @media (max-width: 600px) {
    font-size: 1.8rem;
  }
`;

const CheckboxesContainer = styled.div`
  margin-bottom: 2.4rem;
`;

const CheckboxContainer = styled.div`
  width: 32.4rem;
  margin: 0 auto 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LoginCheckbox = styled(Checkbox)`
  flex-shrink: 0;
`;

const LoginChecboxChecked = styled(CheckboxChecked)`
  flex-shrink: 0;
`;

const PrivacyText = styled.span`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  text-align: left;
  font-size: 14px;
  line-height: 30px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-color: #000000;
  color: #333333;
  cursor: pointer;
`;
