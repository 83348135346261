import { createContext, useEffect, useState } from "react";

// firebase imports
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

// firebase setup and initialization

const firebaseConfig = {
  apiKey: "AIzaSyBgy0mtK6-S8nla6ADZAj5ltiG-t1oIy68",
  authDomain: "auth.grrrammar.de",
  projectId: "grrr-9ed3d",
  storageBucket: "grrr-9ed3d.appspot.com",
  messagingSenderId: "284179009590",
  appId: "1:284179009590:web:104f9d247a06f70e9ec897",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

// Context

export const FirebaseContext = createContext();

export const FirebaseContextProvider = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState(null);

  // auth state change listener
  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((user) => {
      setLoggedUser(user);
    });
    return unsubscribe;
  }, []);

  const onAuthStateChangedListener = (callback) =>
    onAuthStateChanged(auth, callback);

  // sign in

  const signInUser = async ({ email, password }) => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      return res.user;
    } catch (err) {
      return err;
    }
  };

  // sign up user

  const signUpUser = async ({ email, password, settings }) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const userDocRef = doc(db, "users", res.user.uid);
      const userSnapshot = await getDoc(userDocRef);
      if (!userSnapshot.exists()) {
        const { email } = res.user;
        const createdAt = new Date();
        try {
          await setDoc(userDocRef, {
            email,
            createdAt,
            settings,
          });
        } catch (error) {}
      }
      return res.user;
    } catch (err) {
      return err;
    }
  };

  // sign out
  const signOutUser = () => {
    signOut(auth);
  };

  // export methods and values
  const value = {
    loggedUser,
    signOutUser,
    signInUser,
    signUpUser,
  };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
