import { useContext, useState, useEffect } from "react";
import styled from "styled-components";

// components
import Button from "./button";

// context
import { StatusBarContext } from "../context/status-bar.context";
import { QuestionsContext } from "../context/questions.context";

// images
import { ReactComponent as ClockSvg } from "../img/clock.svg";
import { ReactComponent as GameOverHeart } from "../img/gameover-heart.svg";
import { ReactComponent as GameOverDoneSvg } from "../img/gameover-done.svg";

const formatTime = (timeLeft) => {
  let minutes = Math.floor(timeLeft / 60);
  let seconds = timeLeft - minutes * 60;
  if (seconds >= 0 && seconds < 10) {
    seconds = `0${seconds}`;
  } else seconds = "" + seconds;
  return `${minutes}:${seconds}`;
};

const calculateScore = (questions, hints) => {
  let score = questions - hints;
  return score;
};

const fetchAndUpdateLocalStorageScore = (currentScore) => {
  const data = +JSON.parse(localStorage.getItem("bestscore"));
  if (!data) {
    localStorage.setItem("bestscore", JSON.stringify(currentScore));
    return currentScore;
  }
  if (data < currentScore) {
    localStorage.setItem("bestscore", JSON.stringify(currentScore));
  }
  return data;
};

const GameOver = () => {
  const {
    gameIsFinished,
    gameFinishedText,
    timeLeft,
    setGameIsOn,
    setGameIsFinished,
  } = useContext(StatusBarContext);
  const {
    questionsCount,
    mistakesCount,
    hintsCount,
    initialTime,
    setQuestionsLeft,
  } = useContext(QuestionsContext);
  const [finalScore, setFinalScore] = useState(null);
  const [bestScore, setBestScore] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setFinalScore(
        calculateScore(
          questionsCount,
          mistakesCount,
          hintsCount,
          timeLeft,
          initialTime
        )
      );
      setBestScore(
        fetchAndUpdateLocalStorageScore(
          calculateScore(
            questionsCount,
            mistakesCount,
            hintsCount,
            timeLeft,
            initialTime
          )
        )
      );
    }, 500);
  }, [gameIsFinished]);

  useEffect(() => {
    if (!finalScore) {
      return;
    }
  }, [finalScore]);

  const restartHandler = () => {
    setQuestionsLeft(10);
    setGameIsFinished(false);
    setGameIsOn(false);
  };

  const icon = () => {
    if (gameFinishedText === "You've run out of time") {
      return <ClockSvg />;
    }
    if (gameFinishedText === "You've run out of lives!") {
      return <GameOverHeart />;
    }
    if (gameFinishedText === "Finished! Well done") {
      return <GameOverDoneSvg />;
    }
  };

  const calculateCorrectAnswer = () => {
    let x = questionsCount - hintsCount;
    if (x < 0) {
      x = 0;
    }
    return x;
  };

  return (
    <>
      {gameIsFinished && (
        <GameoverOverlay>
          <GameoverCardContainer>
            <GameoverMessage>{gameFinishedText}</GameoverMessage>
            <GameoverCircle>
              <GameoverFinalScore>
                {calculateCorrectAnswer()}
              </GameoverFinalScore>
            </GameoverCircle>
            <GameoverScoreCaption>CORRECT ANSWERS</GameoverScoreCaption>
            <GameoverButtonContainer>
              <Button
                text="Try again"
                color="#3ED04C"
                onClickHandler={restartHandler}
              />
            </GameoverButtonContainer>
          </GameoverCardContainer>
        </GameoverOverlay>
      )}
      )
    </>
  );
};

export default GameOver;

// styles

const GameoverOverlay = styled.div`
  position: fixed; /* Sit on top of the page content */ /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
`;

const GameoverCardContainer = styled.div`
  width: 95.5rem;
  height: 60rem;
  margin: 16rem auto;
  background: #f8f7f6;
  border: 1px solid #ff57bc;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.45);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    margin: 0;
    background: #f8f7f6;
    border: 1px solid #ff57bc;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.45);
    border-radius: 30px;
  }
`;

const GameoverMessage = styled.div`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1400ff;
  text-align: center;
  margin-top: 1.5rem;
  @media (max-width: 600px) {
    font-size: 2.8rem;
  }
`;

const GameoverCircle = styled.div`
  width: 18rem;
  height: 18rem;
  background: #ff57bc;
  border-radius: 50%;
  margin: 4.5rem auto 2.8rem auto;
  display: flex;
`;

const GameoverFinalScore = styled.div`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 8rem;
  line-height: 4.6rem;
  color: #ffffff;
  margin: auto;
  letter-spacing: -0.02em;
`;

const GameoverScoreCaption = styled.div`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 4.6rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ff57bc;
`;

const GameoverButtonContainer = styled.div`
  display: flex;
  width: 64.4rem;
  margin: 5.5rem auto 0rem auto;
  justify-content: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
