import styled from "styled-components";

const Footer = () => {
  return (
    <FooterContaner>
      <Grrr>
        <Copywrite>©</Copywrite> Grrrammar.de 2022
      </Grrr>
      <FooterText>
        Grrrammar’s <PrivacyText>privacy policy</PrivacyText> is simple. We
        don’t use cookies and don't track where you click on the website. When
        you log in, we store your email address. Yet as of now, we don't even
        log your learning progress — this functionality will be introduced
        later. We’ll never message you without your consent.
      </FooterText>
      <Contacts>
        <ContactText>Contact us:</ContactText> hello@grrrammar.de
      </Contacts>
    </FooterContaner>
  );
};

export default Footer;

// styles

const FooterContaner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24rem;
  height: 40rem;
  background: #2a262a;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
  text-align: left;
  padding-left: 7rem;
  padding-top: 5.5rem;
  color: #fffefe;
  @media (max-width: 600px) {
    padding-left: 1rem;
    width: vw80;
  }
`;

const Grrr = styled.div`
  color: #3ed04c;
  padding-bottom: 4rem;
`;

const FooterText = styled.div`
  width: 78rem;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

const Contacts = styled.div`
  padding-top: 4rem;
  @media (max-width: 600px) {
    width: 90%;
    padding-bottom: 4rem;
  }
`;

const PrivacyText = styled.span`
  color: #ff57bc;
`;

const ContactText = styled.span`
  color: #ffe500;
`;

const Copywrite = styled.div`
  display: inline-block;
  font-size: 3.4rem;
  transform: translateY(0.3rem);
`;
